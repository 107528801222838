<app-navbar></app-navbar>
<!-- Teachers Block -->
<div class="teacher-info-block">
    <div class="container">
        <div class="info">
            <div class="title d-flex flex-wrap align-items-center justify-content-center justify-sm-content-start mb-sm-4">
                <h2 class="mb-0 mr-3 my-3">{{this.websiteService.pageTitle}}</h2>
                <div class="teacher-search search-box ml-md-auto my-3">
                    <!-- <div class="d-flex align-items-center">
                        <input type="search" [(ngModel)]="term" placeholder="Search">
                        <button class="btn">Search</button>
                    </div> -->
                </div>
            </div>
            
            <!-- <p class="px-0 px-md-5 text-center mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p> -->
        </div>

        <div class="teachers-member">
            <div class="row employeefilter" style="margin: 0;">
                <h2 class="mb-0 mr-3 my-3"></h2>
                <div class="teacher-search search-box ml-md-auto my-3" style="background-color:none;">
                    <!-- <button id="btn0" class="btn" (click)="filterEmployeeList('school','teacher',0)">Teacher(School)</button>
                    <button id="btn1" class="btn" (click)="filterEmployeeList('college','teacher',1)">Teacher(College)</button>
                    <button id="btn2" class="btn" (click)="filterEmployeeList('','staff',2)">Staff</button>
                    <button id="btn3" class="btn active" (click)="filterEmployeeList('','',3)">All Employee</button> -->
                </div>
            </div>
            <div class="row">
                
                <ng-container *ngFor="let item of websiteService.managingcommitteeList | filter:term;let i = index">
                    <div class="col-lg-4 col-sm-6" style="clear: both;">
                        <div class="teacher-item rounded text-center">
                            <div class="thumb rounded-circle d-flex align-items-center justify-content-center overflow-hidden mx-auto">
                                <img class="img-fluid" src="{{item.PhotoUrl?.replace('~/Uploads',websiteService.apiimagePath)}}" alt="{{item.Name}}">
                            </div>
                            <div class="name-designation py-3">
                                <span>{{item.Name}}</span> <br>
                                <strong>{{item.Designation}}</strong> <br>
                                <!-- BBA, EMBA(DU), ICMA <br> -->
                                NID: {{item.NID}}<br>
                                Contact: {{item.ContactNo}}
                            </div>
                            <div class="contact-info py-2">
                                <a href="mailto:{{item.Email}}">{{item.Email}}</a>
                                <!-- <a href="tel:+88-01711068145">+88-01711068145</a> -->
                            </div>
    
                            <!-- <div class="contact-social d-flex align-items-center justify-content-center pb-2">
                                <a href="#">
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                                <a href="#">
                                    <i class="fab fa-linkedin"></i>
                                </a>
                                <a href="#">
                                    <i class="fab fa-whatsapp"></i>
                                </a>
                                <a href="#">
                                    <i class="fab fa-twitter"></i>
                                </a>
                                <a href="#">
                                    <i class="fab fa-instagram"></i>
                                </a>
                            </div> -->
                        </div> <!-- Teacher Item -->
                    </div>
                </ng-container>
            </div>

            <!-- <div class="load-more text-right mt-4">
                <button class="btn btn-danger">Load More..</button>
            </div> -->
        </div>
    </div>
</div>
<app-footer></app-footer>