import { Component, HostListener, ViewChild, ElementRef, OnInit, Input } from "@angular/core";

@Component({
  selector: 'app-teacher-details-carousel',
  templateUrl: './teacher-details-carousel.component.html',
  styleUrls: ['./teacher-details-carousel.component.css']
})
export class TeacherDetailsCarouselComponent implements OnInit{
  @Input() dataArray:string[];
  totalCards: number;
  currentPage: number = 1;
  pagePosition: string = "0%";
  cardsPerPage: number;
  totalPages: number;
  overflowWidth: string;
  cardWidth: string;
  containerWidth: number;
  @ViewChild("container", { static: true, read: ElementRef })
  container: ElementRef;

  @ViewChild("card", { static: true, read: ElementRef })
  card: ElementRef;


  @HostListener("window:resize") windowResize() {
    let newCardsPerPage = this.getCardsPerPage();
    if (newCardsPerPage != this.cardsPerPage) {
      this.cardsPerPage = newCardsPerPage;
      this.initializeSlider();
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages;
        this.populatePagePosition();
      }
    }
  }

  ngOnInit() {
    console.log("Carousel Loaded");
    console.log(this.dataArray);
    this.totalCards = this.dataArray.length;
    this.cardsPerPage = this.getCardsPerPage();
    this.initializeSlider();
  }

  initializeSlider() {
    this.totalPages = Math.ceil(this.totalCards / this.cardsPerPage);
    this.overflowWidth = `calc(${this.totalPages * 100}% + ${this.totalPages *
      10}px)`;
    this.cardWidth = `calc((${100 / this.totalPages}% - ${this.cardsPerPage *
      10}px) / ${this.cardsPerPage})`;
  }

  getCardsPerPage() {
    var numOfCard = Math.floor(this.container.nativeElement.offsetWidth / 300);
    // var numOfCard = Math.floor(this.container.nativeElement.offsetWidth / 7.5);
    if(numOfCard < 1){
      return 1;
    }else{
      return numOfCard;
    }

  }

  changePage(incrementor) {
    this.currentPage += incrementor;
    this.populatePagePosition();
  }

  populatePagePosition() {
    this.pagePosition = `calc(${-100 * (this.currentPage - 1)}% - ${10 *
      (this.currentPage - 1)}px)`;
  }


}
