import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { WebsiteService } from '../shared/website.service';
import { Router } from '@angular/router';
import { HttpClient  } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-students-information',
  templateUrl: './students-information.component.html',
  styleUrls: ['./students-information.component.css']
})
export class StudentsInformationComponent implements OnInit {
  term: string;
  allLoaded=0;
  totalStudent=0;
  constructor(private route: ActivatedRoute,private router: Router, public websiteService: WebsiteService,public http:HttpClient) { }

  ngOnInit() {
    this.allLoaded =0;
    this.loadStudent(this.websiteService.startPage,this.websiteService.pageSize);
  }
  loadStudent(start, pageSize){
    this.websiteService.getAllStudent(start, pageSize).subscribe(
      (data : any) =>{        
        this.websiteService.studentList= data;
        this.totalStudent = this.websiteService.studentList.length;
        //
        this.hideloader();
        this.hideloaderBottom();
      });
  }
  hideloader() {   
    // Setting display of spinner 
    // element to none 
    document.getElementById('loadingstatus').style.display = 'none'; 
} 
showloader() {   
  // Setting display of spinner 
  // element to none 
  document.getElementById('loadingstatus').style.display = 'block'; 
} 
hideloaderBottom() {   
  // Setting display of spinner 
  // element to none 
  document.getElementById('loadingstatus2').style.display = 'none'; 
} 
showloaderBottom() {   
// Setting display of spinner 
// element to none 
document.getElementById('loadingstatus2').style.display = 'block'; 
} 
  loadMore(e){
    if(this.allLoaded ==0){
    this.showloader();
    this.showloaderBottom();
    this.websiteService.startPage= this.websiteService.startPage+100;
    this.websiteService.getAllStudent(this.websiteService.startPage, this.websiteService.pageSize).subscribe(
      (data : any) =>{        
        //this.websiteService.studentList.push(data);
        data.forEach((item, index) => {
          
          this.websiteService.studentList.push(item);        
        });
        this.totalStudent = this.websiteService.studentList.length;
        this.hideloader();
        this.hideloaderBottom();
      });
    }
  }
  loadAllStudent(e){
    this.showloader();
    this.showloaderBottom();
    this.websiteService.startPage=0;
    this.websiteService.pageSize=10000;
    this.loadStudent(this.websiteService.startPage,this.websiteService.pageSize);
    this.allLoaded =1;
  }
}
