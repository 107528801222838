import { Component,ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { Observable } from 'rxjs';
import { WebsiteService } from '../shared/website.service';

@Component({
  selector: 'app-test-photogallery',
  templateUrl: './test-photogallery.component.html',
  styleUrls: ['./test-photogallery.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestPhotogalleryComponent implements OnInit {
  
  items: GalleryItem[];
  gallerydata = [];
  imageData : any;//this.gallerydata;//this.loadGallery();
  
  constructor(public gallery: Gallery, public lightbox: Lightbox,public websiteService: WebsiteService) { this.loadGallery();}

  ngOnInit(): void {
    //this.loadGallery();
    this.items = this.imageData.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    /** Lightbox Example */

    // Get a lightbox gallery ref
    const lightboxRef = this.gallery.ref('lightbox');

    // Add custom gallery config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top
    });

    // Load items into the lightbox gallery ref
    lightboxRef.load(this.items);
  }
  loadGallery(){
    this.websiteService.getGallery().subscribe(
      (data2 : any) =>{      
        for (let i=0; i<Object.keys(data2).length; i++) {
          this.gallerydata.push({
            srcUrl: data2[i].ImageUrl,
            previewUrl: data2[i].ImageUrl,
          });
        }
        this.imageData = this.gallerydata;        
      });
      
  }
  //file upload event  
fileChange(event) {  
      
    let fileList: FileList = event.target.files;  
    if (fileList.length > 0) {  
    let file: File = fileList[0];  
    let formData: FormData = new FormData();  
    formData.append('uploadFile', file, file.name);  
    let headers = new Headers()  
    //headers.append('Content-Type', 'json');  
    //headers.append('Accept', 'application/json');  
    //let options = new RequestOptions({ headers: headers });  
    /*let apiUrl1 = "/api/UploadFileApi";  
    this.http.post(apiUrl1, formData, options)  
    .map(res => res.json())  
    .catch(error => Observable.throw(error))  
    .subscribe(  
    data => console.log('success'),  
    error => console.log(error)  
    )  
    }  
    window.location.reload();  */
    this.websiteService.uploadFile(formData);
  }  

}
}
const data = [
  {
    srcUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg',
    previewUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg'
  },
  {
    srcUrl: 'https://preview.ibb.co/kPE1D6/clouds.jpg',
    previewUrl: 'https://preview.ibb.co/kPE1D6/clouds.jpg'
  },
  {
    srcUrl: 'https://preview.ibb.co/mwsA6R/img7.jpg',
    previewUrl: 'https://preview.ibb.co/mwsA6R/img7.jpg'
  },
  {
    srcUrl: 'https://preview.ibb.co/kZGsLm/img8.jpg',
    previewUrl: 'https://preview.ibb.co/kZGsLm/img8.jpg'
  }
];
