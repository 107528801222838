import { Component, OnInit } from '@angular/core';
import { WebsiteService } from '../shared/website.service';
import { Router } from '@angular/router';
import { HttpClient  } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-notice-details',
  templateUrl: './notice-details.component.html',
  styleUrls: ['./notice-details.component.css']
})
export class NoticeDetailsComponent implements OnInit {
  deviceInfo = null;
  constructor(private route: ActivatedRoute,private router: Router, public websiteService: WebsiteService,public http:HttpClient, private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {      
      this.websiteService.noticeId=params['nid'];
    });
    this.websiteService.notice="";
    this.deviceInfo = this.deviceService.getDeviceInfo();
    //alert(this.deviceService.isMobile());
    
    this.websiteService.getNoticeByIdWithCheckDevice(this.websiteService.noticeId,this.deviceService.isMobile()).subscribe(
      (data : any) =>{       
        this.websiteService.notice= data;
        //if(this.websiteService.notice.NoticeID == 0){
          //this.websiteService.notice.PageContent="";
        //}
        //if(this.websiteService.pageContent.Title == null){
          //this.websiteService.pageContent.Title="";
        //}
      });
  }

}
