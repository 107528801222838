<app-navbar></app-navbar>
<div style="border:solid 6px whitesmoke;background-color: gray;">
    <div class="container site-content">
        <div class="row">
            <div class="col col-md-12">
                <div class="card box-shadow">
                    <div class="card-header header-shadow">
                        <b>Result</b>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-2">
                                <select style="width: 100%;" id="ddlAcademicYear" (change)="academicYearChange($event)">
                                    <option value="0">Select Session</option>
                                    <option *ngFor="let item of websiteService.academicYear;let i = index" value="{{item.AcademicYearID}}">
                                        {{item.AcademicName}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-12 col-sm-6 col-md-2">
                                <select style="width: 100%;" (change)="classChange($event)">
                                    <option value="0">Select Class</option>
                                    <option *ngFor="let item of websiteService.classList;let i = index" value="{{item.ClassID}}">
                                        {{item.ClassName}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-12 col-sm-6 col-md-2" id="dvGroup" style="display:none;">
                                <select style="width: 100%;" (change)="groupChange($event)">
                                    <option value="0">Select Group</option>
                                    <option *ngFor="let item of websiteService.groupList;let i = index" value="{{item.GroupID}}">
                                        {{item.GroupName}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-12 col-sm-6 col-md-2">
                                <select  (change)="examChange($event)" style="width: 100%;">
                                    <option value="0">Select Exam</option>
                                    <option *ngFor="let item of websiteService.examList;let i = index" value="{{item.ExamID}}"  class="{{item.ExamType}}">
                                        {{item.ExamName}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-12 col-sm-6 col-md-2">
                                <input  style="width: 100%;" (input)="stdcode=$event.target.value" type="text" placeholder="Student Code" />
                            </div>
                            
                        </div>
                        <div class="row" style="text-align: center;margin-top: 10px;">
                            <div class="col">
                                <button class="btn btn-primary" (click)="getResult()">Get Result</button>&nbsp;
                                <!-- <button class="btn btn-primary" (click)="generatePdf(contentToConvert)">Print Result</button> -->
                                <button class="btn btn-primary" (click)="printThePage()">Print Result</button>
                                
                            </div>
                        </div>
                        <div class="row" style="margin-top: 10px;">
                            <div class="d-flex justify-content-center">
                                <div id="loadingstatus">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only" id="loading"></span>
                                    </div>&nbsp;Loading..
                                </div>
                            </div>
                            <div id="resultDv" #contentToConvert style="display: none;">
                                <div style="border: solid 2px gray;font-family: 'Times New Roman', Times, serif; font-size: 18px;">
                                    <div style="text-align: center;width: 100%;">
                                        <img class="img-fluid" style="max-height: 110px;" src="{{this.websiteService.studentResultSummary?.SchoolLogo.replace('~/Uploads',websiteService.apiimagePath)}}" alt="School"><br>
                                    <span style=" font-family: Broadway;font-size: 24px;">{{this.websiteService.studentResultSummary?.SchoolName}}</span> <br>
                                    <span >{{this.websiteService.studentResultSummary?.SchoolAddress}}</span> <br>
                                    <span>{{this.websiteService.studentResultSummary?.ReportCardSetting?.ReportCardTitle}}({{this.selectedExam}})</span>

                                    </div>
                                    <table class="table" style="width:100%">
                                        <tr>
                                            <td style="width: 65%;">
                                                <table class="table" style="width:100%">
                                                    <tr>
                                                        <td>Academic Year</td>
                                                        <td>{{this.selectedAcademicYear}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Examination</td>
                                                        <td>{{this.selectedExam}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Version</td>
                                                        <td>{{this.websiteService.studentResultSummary?.ResultSummary?.VersionName}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Name Of Student</td>
                                                        <td>{{this.websiteService.studentResultSummary?.ResultSummary?.StudentName}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Student ID</td>
                                                        <td>{{this.websiteService.studentResultSummary?.ResultSummary?.StudentUniqueCode}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Class</td>
                                                        <td>{{this.websiteService.studentResultSummary?.ResultSummary?.ClassName}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Shift</td>
                                                        <td>{{this.websiteService.studentResultSummary?.ResultSummary?.ShiftName}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Section</td>
                                                        <td>{{this.websiteService.studentResultSummary?.ResultSummary?.SectionName}}</td>
                                                    </tr>
                                                    
                                                </table>
                                            </td>
                                            <td style="width: 35%;">
                                                <table class="table table-striped" style="width:100%">
                                                    <thead>
                                                        <tr>
                                                            <th>LG</th>
                                                            <th>Marks Range</th>
                                                            <th>Comments</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of this.websiteService.studentResultSummary?.ResultGrade;let i = index">
                                                            <td>{{item.Grade}}</td>
                                                            <td>{{item.LowerPercentage}}-{{item.UpperPercentage}}</td>
                                                            <td>{{item.ResultComments}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                    <div id="termResult">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th rowspan="2">Subject</th>
                                                    <th *ngIf="this.websiteService.studentResultSummary?.PartCourseCount > 0" rowspan="2">Course</th>
                                                    <th rowspan="2">Full Mark</th>
                                                    <th *ngIf="this.websiteService.studentResultSummary?.IsTermDisplayOrderGrater == true" [attr.colspan]="this.websiteService.studentResultSummary?.NumberOfSegment" style="text-align: center;">Obtained Mark</th>
                                                    <th rowspan="2"  *ngFor="let item of this.websiteService.studentResultSummary?.ExamTypeList;let i = index">{{item.ExamTypeName}}</th>
                                                    <th *ngIf="this.websiteService.studentResultSummary?.IsTermDisplayOrderGrater == false" [attr.colspan]="this.websiteService.studentResultSummary?.NumberOfSegment" style="text-align: center;">Obtained Mark</th>
                                                    <th rowspan="2">Total Mark</th>
                                                    <th rowspan="2">Grade</th>
                                                    <th rowspan="2">GPA</th>
                                                </tr>
                                                <tr>
                                                    <th *ngIf="this.websiteService.studentResultSummary?.NeedSubjectiveColumn">{{this.websiteService.studentResultSummary?.ReportCardSetting?.SubjectiveLabel}}</th>
                                                    <th *ngIf="this.websiteService.studentResultSummary?.NeedObjectiveColumn">{{this.websiteService.studentResultSummary?.ReportCardSetting?.ObjectiveLabel}}</th>
                                                    <th *ngIf="this.websiteService.studentResultSummary?.NeedPracticalColumn">{{this.websiteService.studentResultSummary?.ReportCardSetting?.PracticalLabel}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr  *ngFor="let item of this.websiteService.studentResultSummary?.ResultDetail;let i = index">
                                                    <td *ngIf="item.PartCourse == 999 && item.SubjectSerial==1" style="max-width: 270px;" [attr.rowspan]="item.TotalSubject">
                                                        <span *ngIf="item.PartCourse == 999 && item.TotalSubject > 1">{{item.SubjectName}}</span>
                                                        <span *ngIf="item.PartCourse == 999 && item.TotalSubject ==1 ">{{item.CourseName}}</span>
                                                    </td>
                                                    <td *ngIf="item.PartCourse != 999" style="max-width: 270px;">
                                                        <span *ngIf="item.PartCourse != 999 && item.SubjectSerial==1">{{item.CourseName}}</span>  
                                                        <span *ngIf="item.PartCourse == 999 && item.SubjectSerial==1">{{item.SubjectName}}</span>
                                                    </td>
                                                    <td *ngIf="this.websiteService.studentResultSummary?.PartCourseCount > 0">
                                                    <span *ngIf="item.PartCourse == 999 && item.TotalSubject > 1">{{item.CourseName}}</span>  
                                                    </td>
                                                    <td>{{item.ExamTotalMark}}</td>
                                                    <td *ngIf="this.websiteService.studentResultSummary?.TotalExamOtherType > 0 && this.websiteService.studentResultSummary?.IsTermDisplayOrderGrater == false">
                                                        <span *ngIf="item.ExamType1Mark != null" >{{item.ExamType1Mark*item.ExamTotalMark/100}}</span>
                                                        <span *ngIf="item.ExamType1Mark == null" >-</span>
                                                    </td>
                                                    <td *ngIf="this.websiteService.studentResultSummary?.TotalExamOtherType > 1 && this.websiteService.studentResultSummary?.IsTermDisplayOrderGrater == false">
                                                        <span  *ngIf="item.ExamType2Mark != null">{{item.ExamType2Mark*item.ExamTotalMark/100}}</span>
                                                        <span *ngIf="item.ExamType2Mark == null" >-</span>
                                                    </td>
                                                    <td *ngIf="this.websiteService.studentResultSummary?.TotalExamOtherType > 2 && this.websiteService.studentResultSummary?.IsTermDisplayOrderGrater == false">
                                                        <span *ngIf="item.ExamType3Mark != null">{{item.ExamType3Mark*item.ExamTotalMark/100}}</span>
                                                        <span *ngIf="item.ExamType3Mark == null" >-</span>
                                                    </td>
                                                    <td *ngIf="this.websiteService.studentResultSummary?.TotalExamOtherType > 3 && this.websiteService.studentResultSummary?.IsTermDisplayOrderGrater == false">
                                                        <span *ngIf="item.ExamType4Mark != null">{{item.ExamType4Mark*item.ExamTotalMark/100}}</span>
                                                        <span *ngIf="item.ExamType4Mark == null" >-</span>
                                                    </td>
                                                    <td *ngIf="this.websiteService.studentResultSummary?.NeedSubjectiveColumn">
                                                        <span *ngIf="item.ExamSubjectiveMark > 0">{{item.SubjectiveMark}}</span>
                                                        <span *ngIf="item.ExamSubjectiveMark == 0">-</span>
                                                    </td>
                                                    <td *ngIf="this.websiteService.studentResultSummary?.NeedObjectiveColumn">
                                                        <span *ngIf="item.ExamObjectiveMark > 0">{{item.ObjectiveMark}}</span>
                                                        <span *ngIf="item.ExamObjectiveMark == 0">-</span>
                                                    </td>
                                                    <td *ngIf="this.websiteService.studentResultSummary?.NeedPracticalColumn">
                                                        <span *ngIf="item.ExamPracticalMark > 0">{{item.PracticalMark}}</span>
                                                        <span *ngIf="item.ExamPracticalMark == 0">-</span>
                                                    </td>
                                                    <td *ngIf="this.websiteService.studentResultSummary?.TotalExamOtherType > 0 && this.websiteService.studentResultSummary?.IsTermDisplayOrderGrater == true">
                                                        <span *ngIf="item.ExamType1Mark != null" >{{item.ExamType1Mark*item.ExamTotalMark/100}}</span>
                                                        <span *ngIf="item.ExamType1Mark == null" >-</span>
                                                    </td>
                                                    <td *ngIf="this.websiteService.studentResultSummary?.TotalExamOtherType > 1 && this.websiteService.studentResultSummary?.IsTermDisplayOrderGrater == true">
                                                        <span  *ngIf="item.ExamType2Mark != null">{{item.ExamType2Mark*item.ExamTotalMark/100}}</span>
                                                        <span *ngIf="item.ExamType2Mark == null" >-</span>
                                                    </td>
                                                    <td *ngIf="this.websiteService.studentResultSummary?.TotalExamOtherType > 2 && this.websiteService.studentResultSummary?.IsTermDisplayOrderGrater == true">
                                                        <span *ngIf="item.ExamType3Mark != null">{{item.ExamType3Mark*item.ExamTotalMark/100}}</span>
                                                        <span *ngIf="item.ExamType3Mark == null" >-</span>
                                                    </td>
                                                    <td *ngIf="this.websiteService.studentResultSummary?.TotalExamOtherType > 3 && this.websiteService.studentResultSummary?.IsTermDisplayOrderGrater == true">
                                                        <span *ngIf="item.ExamType4Mark != null">{{item.ExamType4Mark*item.ExamTotalMark/100}}</span>
                                                        <span *ngIf="item.ExamType4Mark == null" >-</span>
                                                    </td>
                                                    <td>{{item.TotalMarks}}</td>
                                                    <td>{{item.Grade}}</td>
                                                    <td>{{item.GPA}}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                            </tfoot>
                                        </table>
                                        <table class="table">
                                            <thead>
                                            <tr>
                                                <th>Working Days</th>
                                                <th>Present</th>
                                                <th>Absent</th>
                                                <th>Total Marks</th>
                                                <th>Total Marks Obtained</th>
                                                <th>Percentage</th>
                                                <th>GPA</th>
                                                <th>Letter Grade</th>
                                                <th *ngIf="this.websiteService.studentResultSummary?.ReportCardSetting?.ShowSectionPosition == true">Section Position</th>
                                                <th *ngIf="this.websiteService.studentResultSummary?.ReportCardSetting?.ShowShiftPosition == true">Shift Position</th>
                                                <th *ngIf="this.websiteService.studentResultSummary?.ReportCardSetting?.ShowClassPosition == true">Class Position</th>
                                                <th>Comments</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                <tr style="text-align: center;">
                                                    <td><span>{{this.websiteService.studentResultSummary?.TotalWorkingDays}}</span></td>
                                                    <td>{{this.websiteService.studentResultSummary?.TotalPresent}}</td>
                                                    <td>{{this.websiteService.studentResultSummary?.TotalAbsent}}</td>
                                                    <td>{{this.websiteService.studentResultSummary?.ResultSummary?.ExamGrandTotal}}</td>
                                                    <td>{{this.websiteService.studentResultSummary?.ResultSummary?.TotalMarks}}</td>
                                                    <td>{{this.websiteService.studentResultSummary?.ResultSummary?.ExamGrandTotal > 0 ?(this.websiteService.studentResultSummary?.ResultSummary?.TotalMarks*100/this.websiteService.studentResultSummary?.ResultSummary?.ExamGrandTotal).toFixed(2):0}}%</td>
                                                    <td>{{this.websiteService.studentResultSummary?.ResultSummary?.CGPA}}</td>
                                                    <td>{{this.websiteService.studentResultSummary?.ResultSummary?.Grade}}</td>
                                                    <td *ngIf="this.websiteService.studentResultSummary?.ReportCardSetting?.ShowSectionPosition == true">{{this.websiteService.studentResultSummary?.ResultSummary?.PositionInSection}}</td>
                                                    <td *ngIf="this.websiteService.studentResultSummary?.ReportCardSetting?.ShowShiftPosition == true">{{this.websiteService.studentResultSummary?.ResultSummary?.PositionInShift}}</td>
                                                    <td *ngIf="this.websiteService.studentResultSummary?.ReportCardSetting?.ShowClassPosition == true">{{this.websiteService.studentResultSummary?.ResultSummary?.PositionClass}}</td>
                                                    <td>{{this.websiteService.studentResultSummary?.ResultComment}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style="height: 10px;">&nbsp;</div>
                        </div>
                    </div>


                        <div id="summativeResult" style="display: none; width: 100%;">
                            <div style="border: solid 2px gray;font-family: 'Times New Roman', Times, serif;">
                                <div style="text-align: center;width: 100%;">
                                    <img class="img-fluid" style="max-height: 110px;" src="{{this.websiteService.studentSummativeResult?.SchoolLogo.replace('~/Uploads',websiteService.apiimagePath)}}" alt="School"><br>
                                <span style=" font-family: Broadway;font-size: 24px;">{{this.websiteService.studentSummativeResult?.SchoolName}}</span> <br>
                                <span >{{this.websiteService.studentSummativeResult?.SchoolAddress}}</span> <br>
                                <span>{{this.websiteService.studentSummativeResult?.ReportCardSetting?.ReportCardTitle}}({{this.selectedExam}})</span>

                                </div>
                                <table class="table" style="width:100%">
                                    <tr>
                                        <td style="width: 65%;">
                                            <table class="table" style="width:100%">
                                                <tr>
                                                    <td>Academic Year</td>
                                                    <td>{{this.selectedAcademicYear}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Examination</td>
                                                    <td>{{this.selectedExam}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Version</td>
                                                    <td>{{this.websiteService.studentSummativeResult?.SummativeReportResult?.VersionName}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Name Of Student</td>
                                                    <td>{{this.websiteService.studentSummativeResult?.SummativeReportResult?.StudentName}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Student ID</td>
                                                    <td>{{this.websiteService.studentSummativeResult?.SummativeReportResult?.StudentUniqueCode}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Class</td>
                                                    <td>{{this.websiteService.studentSummativeResult?.SummativeReportResult?.ClassName}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Shift</td>
                                                    <td>{{this.websiteService.studentSummativeResult?.SummativeReportResult?.ShiftName}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Section</td>
                                                    <td>{{this.websiteService.studentSummativeResult?.SummativeReportResult?.SectionName}}</td>
                                                </tr>
                                                
                                            </table>
                                        </td>
                                        <td style="width: 35%;">
                                            <table class="table table-striped" style="width:100%">
                                                <thead>
                                                    <tr>
                                                        <th>LG</th>
                                                        <th>Marks Range</th>
                                                        <th>Comments</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of this.websiteService.studentSummativeResult?.ResultGrade;let i = index">
                                                        <td>{{item.Grade}}</td>
                                                        <td>{{item.LowerPercentage}}-{{item.UpperPercentage}}</td>
                                                        <td>{{item.ResultComments}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <table class="table table-striped" border="1">
                                    <thead>
                                        <tr>
                                            <th rowspan="2">Subject</th>
                                            <th rowspan="2">Full Mark</th>
                                            <th  [attr.colspan]="this.websiteService.studentSummativeResult?.NumberOfSegment" *ngFor="let item of this.websiteService.studentSummativeResult?.SummativeExam; let i=index">{{item.ExamName}}</th>
                                            <th rowspan="2">Total Marks</th>
                                            <th rowspan="2">Marks Obtained(%)</th>
                                            <th rowspan="2">Grade</th>
                                            <th rowspan="2">GP</th>
                                        </tr>
                                        <tr>
                                            <ng-container *ngFor="let item of this.websiteService.studentSummativeResult?.SummativeExam; let i=index">
                                                <th *ngIf="this.websiteService.studentSummativeResult?.NeedSubjectiveColumn">{{this.websiteService.studentSummativeResult?.ReportCardSetting?.SubjectiveLabel}}</th>
                                                    <th *ngIf="this.websiteService.studentSummativeResult?.NeedObjectiveColumn">{{this.websiteService.studentSummativeResult?.ReportCardSetting?.ObjectiveLabel}}</th>
                                                    <th *ngIf="this.websiteService.studentSummativeResult?.NeedPracticalColumn">{{this.websiteService.studentSummativeResult?.ReportCardSetting?.PracticalLabel}}</th>
                                                
                                                <th>Total</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of this.websiteService.studentSummativeResult?.SummativeExamCourseList; let i=index">
                                            <td>{{item.CourseName}}</td>
                                            <td>{{this.getExamTotalMark(item.CourseId)}}</td>
                                            <ng-container *ngFor="let item2 of this.websiteService.studentSummativeResult?.SummativeExam; let i=index">
                                                <td>{{this.filteredResult(item2.ExamId, item.CourseId)[0]?.SubjectiveMark}}</td>
                                                <td>{{this.filteredResult(item2.ExamId, item.CourseId)[0]?.ObjectiveMark}}</td>
                                                <td>{{this.filteredResult(item2.ExamId, item.CourseId)[0]?.PracticalMark}}</td>
                                                <td>{{this.filteredResult(item2.ExamId, item.CourseId)[0]?.TotalMark}}</td>
                                            </ng-container>
                                            <td>{{this.getTotalObtainedMark(item.CourseId)}}</td>
                                            <td>{{this.getTotalMarkObtainedPercentage(item.CourseId)}}</td>
                                            <td>{{this.getStudentGrade(item.CourseId)}}</td>
                                            <td>{{this.getStudentGPA(item.CourseId)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th>Working Days</th>
                                        <th>Present</th>
                                        <th>Absent</th>
                                        <th>Total Marks</th>
                                        <th>Total Marks Obtained</th>
                                        <th>Percentage</th>
                                        <th>GPA</th>
                                        <th>Letter Grade</th>
                                        <th>Comments</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="text-align: center;">
                                            <td><span>{{this.websiteService.studentSummativeResult?.TotalWorkingDays}}</span></td>
                                            <td>{{this.websiteService.studentSummativeResult?.TotalPresent}}</td>
                                            <td>{{this.websiteService.studentSummativeResult?.TotalAbsent}}</td>
                                            <td>{{this.websiteService.studentSummativeResult?.SummativeReportResult?.ExamTotalMark}}</td>
                                            <td>{{this.websiteService.studentSummativeResult?.SummativeReportResult?.TotalMark}}</td>
                                            <td>{{this.websiteService.studentSummativeResult?.SummativeReportResult?.ExamTotalMark > 0 ?(this.websiteService.studentSummativeResult?.SummativeReportResult?.TotalMark*100/this.websiteService.studentSummativeResult?.SummativeReportResult?.ExamTotalMark).toFixed(2):0}}%</td>
                                            <td>{{this.websiteService.IsFailedAnySubject == true? 0 : this.websiteService.studentSummativeResult?.SummativeReportResult?.CGPA}}</td>
                                            <td>{{this.websiteService.IsFailedAnySubject == true? 'F' :this.websiteService.studentSummativeResult?.SummativeReportResult?.Grade}}</td>
                                            <td>{{this.websiteService.IsFailedAnySubject == true? 'Fail' : this.websiteService.studentSummativeResult?.ResultComment}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    
<app-footer></app-footer>
