import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { WebsiteService } from '../shared/website.service';
import { Router } from '@angular/router';
import { HttpClient  } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-teacher-information',
  templateUrl: './teacher-information.component.html',
  styleUrls: ['./teacher-information.component.css']
})
export class TeacherInformationComponent implements OnInit {
  term: string;
  allLoaded=0;
  totalTeacher=0;
  selectedTab='';
  constructor(private route: ActivatedRoute,private router: Router, public websiteService: WebsiteService,public http:HttpClient) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {  
      if(params['dept']) {
        this.GetEmployeeByDept(params['dept']);
      }
      else {
        this.websiteService.pageTitle = 'Teacher Information';
        this.allLoaded =0;
        this.loadAllEmployee(0,10000);
        this.loadTeacher(this.websiteService.startPageTeacher,this.websiteService.pageSizeTeacher);
      }
    });
  }
  loadTeacher(start, pageSize){
    this.websiteService.getTeacherInformation(start, pageSize).subscribe(
      (data : any) =>{        
        this.websiteService.teacherList= data;
        this.totalTeacher = this.websiteService.teacherList.length;
        //
        this.hideloader();
        this.hideloaderBottom();
      });
  }
  
  loadMore(e){
    if(this.allLoaded ==0){
    this.showloader();
    this.showloaderBottom();
    this.websiteService.startPageTeacher= this.websiteService.startPageTeacher+100;
    this.websiteService.getTeacherInformation(this.websiteService.startPageTeacher, this.websiteService.pageSizeTeacher).subscribe(
      (data : any) =>{        
        //this.websiteService.studentList.push(data);
        data.forEach((item, index) => {
          
          this.websiteService.teacherList.push(item);        
        });
        this.totalTeacher = this.websiteService.teacherList.length;
        this.hideloader();
        this.hideloaderBottom();
      });
    }
  }
  loadAllEmployee(start, pageSize){
    this.websiteService.getTeacherInformation(start, pageSize).subscribe(
      (data : any) =>{        
        this.websiteService.employeeListAll= data;
      });
  }
  GetEmployeeByDept(dept){
    this.websiteService.getEmployeeList(dept).subscribe(
      (data : any) =>{        
        this.websiteService.teacherList= data;
      });
  }
  filterEmployeeList(department, employeeType, butonNumber){
    
    
    let found = [];
    const searchTerm = 'School';
    let tempList: any;
    tempList = JSON.stringify(this.websiteService.employeeListAll);
    //found = tempList.filter(function(element) {
      //return element.DepartmentName.toLowerCase() == searchTerm.toLowerCase();
    //});
    //let filterEmployeeList = this.websiteService.employeeListAll
                                ///.filter(emp =>{ emp.DepartmentName.toLowerCase().includes(department)});
     for(var i=0;i<4;i++){
      document.getElementById("btn"+i).className=document.getElementById("btn"+i).className.replace('active','');
     }
     document.getElementById("btn"+butonNumber).className = document.getElementById("btn"+butonNumber).className.concat(" active");
  this.websiteService.teacherList = this.websiteService.employeeListAll.filter(emp=>{
      if(employeeType == 'teacher')
        return emp.DepartmentName.toLowerCase().includes(department.toLowerCase()) && emp.IsTeacher === true;
      else if(employeeType == 'staff')
        return emp.IsTeacher === false;
      else
      return emp;
        //return data;
    });
  }

  loadAllTeacher(e){
    this.showloader();
    this.showloaderBottom();
    this.websiteService.startPageTeacher=0;
    this.websiteService.pageSizeTeacher=10000;
    this.loadTeacher(this.websiteService.startPageTeacher,this.websiteService.pageSizeTeacher);
    this.allLoaded =1;
  }
  hideloader() {   
    // Setting display of spinner 
    // element to none 
    document.getElementById('loadingstatus').style.display = 'none'; 
} 
  showloader() {   
    // Setting display of spinner 
    // element to none 
    document.getElementById('loadingstatus').style.display = 'block'; 
  } 
  hideloaderBottom() {   
    // Setting display of spinner 
    // element to none 
    document.getElementById('loadingstatus2').style.display = 'none'; 
  } 
  showloaderBottom() {   
  // Setting display of spinner 
  // element to none 
  document.getElementById('loadingstatus2').style.display = 'block'; 
  } 
}
