<app-navbar></app-navbar>
<!-- <div class="site-content">
<div class="container">
    <div class="rounded mt-4 mt-sm-2" style="background-color: white;">
        Test
    </div>
</div>
</div> -->
<div style="border:solid 6px whitesmoke;background-color: gray;">
<div class="container site-content">
    <div class="row">
        <div class="col col-md-12">
            <div class="card box-shadow">
                <div class="card-header header-shadow">
                    <b>{{this.websiteService.pageContent.Title}}</b>
                </div>
                <div class="card-body">
                    <div [innerHtml]="this.websiteService.unescapeHtmlString(this.websiteService.pageContent.PageContent)  | safe: 'html'"></div>
                    <div [innerHtml]="this.websiteService.unescapeHtmlString(this.websiteService.pageAttachment)  | safe: 'html'"></div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<app-footer></app-footer>
