
<div class='demo-app'>

  <div class='demo-app-sidebar'>
    <div class='demo-app-sidebar-section'>
      <h2>Instructions</h2>
      <ul>
        <li>Select dates and you will be prompted to create a new event</li>
        <li>Drag, drop, and resize events</li>
        <li>Click an event to delete it</li>
      </ul>
    </div>
    <div class='demo-app-sidebar-section'>
      <label>
        <input
          type='checkbox'
          [checked]='calendarVisible'
          (change)='handleCalendarToggle()'
        />
        toggle whole calendar
      </label>
    </div>
    <div class='demo-app-sidebar-section'>
      <label>
        <input
          type='checkbox'
          [checked]='calendarOptions.weekends'
          (change)='handleWeekendsToggle()'
        />
        toggle weekends
      </label>
    </div>
    <div class='demo-app-sidebar-section'>
      <h2>All Events ({{currentEvents.length}})</h2>
      <ul>
        <li *ngFor='let event of currentEvents'>
          <b>{{event.startStr}}</b>
          <i>{{event.title}}</i>
        </li>
      </ul>
    </div>
  </div>

  <div class='demo-app-main'>
    <full-calendar
      *ngIf='calendarVisible'
      [options]='calendarOptions'
    ></full-calendar>
  </div>

</div>
