import { BrowserModule } from '@angular/platform-browser';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  

import {RouterModule} from '@angular/router';
import { appRoutes } from './routes';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import{WebsiteService} from './shared/website.service';
import { HttpClientModule } from '@angular/common/http';
import { NgMarqueeModule } from 'ng-marquee';
import { SafePipe } from './safe.pipe';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { DetailsComponent } from './details/details.component';
import { MoreDetailsComponent } from './more-details/more-details.component';
import { MoreNoticeComponent } from './more-notice/more-notice.component';
import { NoticeDetailsComponent } from './notice-details/notice-details.component';
import { StudentsInformationComponent } from './students-information/students-information.component';
import {FormsModule} from '@angular/forms';
//import {NgxPaginationModule} from 'ngx-pagination';  
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { TeacherInformationComponent } from './teacher-information/teacher-information.component';
import { ManagingCommitteeComponent } from './managing-committee/managing-committee.component';
import { MessageDetailsComponent } from './message-details/message-details.component';
//import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
//import { HTMLEscapeUnescapeModule } from 'html-escape-unescape';
import { MatDialogModule } from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginDialogBodyComponent } from './login-dialog-body/login-dialog-body.component';
import { StudentresultComponent } from './studentresult/studentresult.component';
import { TestFullcalendarComponent } from './test-fullcalendar/test-fullcalendar.component';
import { AcademicCalendarComponent } from './academic-calendar/academic-calendar.component';
import { TestPhotogalleryComponent } from './test-photogallery/test-photogallery.component'; 
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { PhotoGalleryComponent } from './photo-gallery/photo-gallery.component';
import { TeacherDetailsComponent } from './teacher-details/teacher-details.component';
import { TeacherDetailsCarouselComponent } from './teacher-details-carousel/teacher-details-carousel.component';



FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
])

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SafePipe,
    NavbarComponent,
    FooterComponent,
    DetailsComponent,
    MoreDetailsComponent,
    MoreNoticeComponent,
    NoticeDetailsComponent,
    StudentsInformationComponent,
    TeacherInformationComponent,
    ManagingCommitteeComponent,
    MessageDetailsComponent,
    LoginDialogBodyComponent,
    StudentresultComponent,
    TestFullcalendarComponent,
    AcademicCalendarComponent,
    TestPhotogalleryComponent,
    PhotoGalleryComponent,
    TeacherDetailsComponent,
    TeacherDetailsCarouselComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes,{useHash:true}),
    HttpClientModule,
    NgMarqueeModule,
    FormsModule,
    Ng2SearchPipeModule, 
    MatDialogModule, 
    MatCardModule,
    BrowserAnimationsModule,
    FullCalendarModule,
    GalleryModule,
    LightboxModule,
    CommonModule
  ],
  providers: [WebsiteService],
  bootstrap: [AppComponent],
  entryComponents: [LoginDialogBodyComponent]
})
export class AppModule { }
