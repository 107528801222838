import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { WebsiteService } from '../shared/website.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginDialogBodyComponent } from '../login-dialog-body/login-dialog-body.component';
//import { fromEvent, Observable, Subscription } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  selectedIndex: number = 0;
  startIndex: number = 0;
  fblink;
  twitterLink;
  instagramLink;
  linkedinLink;
  menuIndex = 0;
  totalMenuItem;
  menuStart = 0;
  menuEnd = 0;
  isBoardSelected = false;
  isnavOpen: boolean = false;
  selectedCircularId = 0;
  rollNumber = '';
  applicantRollNumber = '';
  private key = CryptoJS.enc.Utf8.parse('4512631236589784');
  private iv = CryptoJS.enc.Utf8.parse('4512631236589784');
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  // @ViewChild('loginDialog') loginDialog: TemplateRef<any>;
  @ViewChild('loginDialog', { static: true }) loginDialog: TemplateRef<any>;

  constructor(private router: Router, public websiteService: WebsiteService, public http: HttpClient, public dialog: MatDialog, private titleService: Title) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    //this.changeIcon();
  }
  changeIcon() {
    this.favIcon.href = 'https://www.google.com/favicon.ico';
  }
  ngOnInit() {
    this.rollNumber = '';
    this.applicantRollNumber = '';
    this.selectedCircularId = 0;
    document.getElementById("menuPrevButton").className = document.getElementById("menuPrevButton").className.concat(' d-none', '');
    this.loadSiteTopMenu(0);
    this.loadSchoolInfo();

    this.loadSocailLinks();
    //alert(this.encryptUsingAES256());
    this.loadCircular();
    this.loadFavicon();
  }
  loadCircular() {
    this.isBoardSelected = false;
    this.websiteService.getCircular().subscribe(
      (data: any) => {
        debugger;
        this.websiteService.circularInfo = data;
        var btnApplyNow = document.getElementById("btnApplyNow");
        var btnPrintProfile = document.getElementById("btnPrintProfile");
        var btnApplyNowBoardSelected = document.getElementById("btnApplyNowBoardSelected");

        if (data.length > 0) {
          btnApplyNow.style.display = "inline-block";
          btnPrintProfile.style.display = "inline-block";
          var modal = document.getElementById("myModal");
          //var btn = document.getElementById("myBtn");
          var span = document.getElementById("close");

          if (data.length == 1) {
            var menuurl = "";
            if (this.websiteService.circularInfo.CircularType == 'Recruitment') {
              //menuurl ="http://localhost:8707/Recruitment/RecruitmentForm.aspx?cid="+this.websiteService.circularInfo[0].AdmissionCerculerID;
              menuurl = "http://" + this.websiteService.systemUrl + "/Recruitment/RecruitmentForm.aspx?cid=" + this.websiteService.circularInfo[0].AdmissionCerculerID;
            }
            else {

              if (data[0].IsBoardSelected == true) {
                btnApplyNow.style.display = "none";
                //btnApplyNowBoardSelected.style.display = "block";
                this.isBoardSelected = true;
                //modal.style.display = "none";
              }
              else {
                //menuurl ="http://localhost:8707/Admission/AdmissionForm.aspx?cid="+this.websiteService.circularInfo[0].AdmissionCerculerID;
                menuurl = "http://" + this.websiteService.systemUrl + "/Admission/AdmissionForm.aspx?cid=" + this.websiteService.circularInfo[0].AdmissionCerculerID;
              }
            }
            //window.open(menuurl, "_self");
            btnApplyNow.onclick = function () {
              window.open(menuurl, "_self");
            };

          }
          else {
            btnApplyNow.onclick = function () {
              modal.style.display = "block";
            };
            span.onclick = function () {
              modal.style.display = "none";
            }
            window.onclick = function (event) {
              if (event.target == modal) {
                modal.style.display = "none";
              }
            }

            //document.getElementById("dvCircular").innerHTML='';
          }
        }
        else {
          btnApplyNow.style.display = "none";
          btnPrintProfile.style.display = "none";
        }
      });
  }

  onChangeRollApplicantProfile(event): void {
    this.applicantRollNumber = event.target.value;
  }
  printProfileWin(): void {
    var modal = document.getElementById("applicanProfileWin");
    modal.style.display = "block";
    var span = document.getElementById("closeapplicanProfileWin");
    span.onclick = function () {
      modal.style.display = "none";
    }
  }
  submitapplicanProfile(): void {
    window.open("http://" + this.websiteService.systemUrl + "/Admission/AdmissionAdmitCard.aspx?admissionRoll=" + this.applicantRollNumber, "_self");
  }
  onChangeRoll(event): void {
    this.rollNumber = event.target.value;
  }
  showBoardSelectedWin(): void {
    var modal = document.getElementById("boardSelectedModal");
    modal.style.display = "block";
    var span = document.getElementById("closeBoardSelectedModal");
    span.onclick = function () {
      modal.style.display = "none";
    }
  }
  submitAdmission(): void {
    debugger;
    this.websiteService.checkBoardRoll(this.selectedCircularId, this.rollNumber).subscribe(
      (data: any) => {
        if (data == false) {
          alert("Sorry you are not selected");
        }
        else {
          window.open("http://" + this.websiteService.systemUrl + "/Admission/AdmissionFormBoard.aspx?cid=" + this.selectedCircularId + "&roll=" + this.rollNumber, "_self");
        }
      }
    );
  }
  showBoardSelectedModal(circularId): void {
    this.selectedCircularId = circularId;
    var modal = document.getElementById("boardSelectedModalInput");
    modal.style.display = "block";
    var span = document.getElementById("closeBoardSelectedModalInput");
    span.onclick = function () {
      modal.style.display = "none";
    }
  }
  encryptUsingAES256() {
    var body = {
      UserName: 'admin',
      Password: 'qweqwe'
    }
    //var obj = { "UserName":"admin", 'Password': 'qweqwe'}
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse("admin|qweqwe"), this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    console.log('Encrypted :' + encrypted);
    this.decryptUsingAES256(encrypted);
    return encrypted;
  }

  decryptUsingAES256(decString) {
    var decrypted = CryptoJS.AES.decrypt(decString, this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    console.log('Decrypted : ' + decrypted);
    console.log('utf8 = ' + decrypted.toString(CryptoJS.enc.Utf8));

  }
  openDialog() {
    var menuurl = "http://" + this.websiteService.systemUrl + "/Login-new.aspx";//"http://localhost:8707/AutoLogin.aspx?tokenkey="+this.encryptUsingAES256();
    //var menuurl="http://localhost:8707/Login-new.aspx";
    //var token =username+"|"+password;
    //var menuurl="http://"+this.websiteService.systemUrl+"/AutoLogin.aspx?tokenkey="+this.encryptUsingAES256(token);
    //var menuurl="http://localhost:8707/Login-new.aspx";

    //window.open(menuurl, "_blank");
    window.open(menuurl, "_self");
    //alert(menuurl);
    //window.open(menuurl, "_blank");
    //
    //const dialogConfig = new MatDialogConfig();
    //dialogConfig.position = {
    //'top': '0',
    //left: '0'
    //};
    //dialogConfig.disableClose = true;
    //dialogConfig.autoFocus = true;
    //this.dialog.open(LoginDialogBodyComponent, dialogConfig);
  }
  showLoginDialog() {
    this.openDialogWithRef(this.loginDialog);
  }
  openDialogWithRef(ref: TemplateRef<any>) {
    this.dialog.open(ref);
  }
  loadSchoolInfo() {
    this.websiteService.getShcoolInfo().subscribe(
      (data: any) => {
        this.websiteService.systemUrl = data.SystemRediectUrl;
        this.websiteService.schoolInfo = data;
        this.websiteService.themeColor = this.getThemeColor(data.ThemeColor);
        //this.websiteService.themeColor=this.getThemeColor('voilet');
        //this.websiteService.navigationColor='linear-gradient(#a4bd31, #414b11)';
        //this.websiteService.navigationColor='linear-gradient(#a4bd31, #414b11)';
        this.websiteService.navigationColor = this.getNavigationColor(data.ThemeColor);
        this.titleService.setTitle(this.websiteService.schoolInfo.SchoolName);
        this.favIcon.href = this.websiteService.schoolInfo.HomeLogoUrl.replace('Uploads', this.websiteService.apiimagePath);//'https://www.google.com/favicon.ico';

      });
  }
  loadFavicon() {
    this.websiteService.getSchoolAttributeByKey("Favicon").subscribe(
      (data: any) => {

        if (data.length > 0) {
          this.favIcon.href = data[0].ImageUrl.replace('Uploads', this.websiteService.apiimagePath);//'https://www.google.com/favicon.ico';
        }
        //this.websiteService.schoolAttribute= data;
      });

  }
  getNavigationColor(selectedTheme) {
    var navColor = 'linear-gradient(#ffc000, #af0006)';
    switch (selectedTheme) {
      case 'black': {
        navColor = 'linear-gradient(#4a4949, #1f1e1e)';
        break;
      }
      case 'blue': {
        navColor = 'linear-gradient(#0386d9, #024874)';
        break;
      }
      case 'green': {
        navColor = 'linear-gradient(#a4bd31, #414b11)';
        break;
      }
      case 'red': {
        navColor = 'linear-gradient(#f32222, #5a0a0a)';
        break;
      }
      case 'theme1': {
        navColor = 'linear-gradient(#448e6a, #224936)';
        break;
      }

      case 'theme2': {
        navColor = 'linear-gradient(#5783e0, #223459)';
        break;
      }
      case 'theme3': {
        navColor = 'linear-gradient(#2de00d, #146306)';
        break;
      }
      case 'theme4': {
        navColor = 'linear-gradient(#d25304, #602603)';
        break;
      }
      case 'theme5': {
        navColor = 'linear-gradient(#1c1c85, #080829)';
        break;
      }
      case 'theme6': {
        navColor = 'linear-gradient(#460146, #1d001d)';
        break;
      }
      case 'theme7': {
        navColor = 'linear-gradient(#018562, #013c2c)';
        break;
      }
      case 'theme8': {
        navColor = 'linear-gradient(#841138, #4a091f)';
        break;
      }
      case 'theme9': {
        navColor = 'linear-gradient(#0a9b48, #06592a)';
        break;
      }
      case 'theme10': {
        navColor = 'linear-gradient(#7c5663, #463138)';
        break;
      }
      case 'theme11': {
        navColor = 'linear-gradient(#01b7b7, #015151)';
        break;
      }
      case 'voilet': {
        navColor = 'linear-gradient(#684c9c, #32254a)';
        break;
      }
      default: {
        //statements; 
        break;
      }
    }
    return navColor;
  }
  getThemeColor(selectedTheme) {
    var themeColor = '#af0006';
    switch (selectedTheme) {
      case 'black': {
        themeColor = '#232323';
        break;
      }
      case 'blue': {
        themeColor = '#006CB0';
        break;
      }
      case 'green': {
        themeColor = '#9eb729';
        break;
      }
      case 'red': {
        themeColor = '#b31818';
        break;
      }
      case 'theme1': {
        themeColor = '#264F3B';
        break;
      }

      case 'theme2': {
        themeColor = '#3B5998';
        break;
      }
      case 'theme3': {
        themeColor = '#23AF0A';
        break;
      }
      case 'theme4': {
        themeColor = '#A74203';
        break;
      }
      case 'theme5': {
        themeColor = '#0E0E44';
        break;
      }
      case 'theme6': {
        themeColor = '#330033';
        break;
      }
      case 'theme7': {
        themeColor = '#006A4E';
        break;
      }
      case 'theme8': {
        themeColor = '#5b0b26';
        break;
      }
      case 'theme9': {
        themeColor = '#087B39';
        break;
      }
      case 'theme10': {
        themeColor = '#5f424c';
        break;
      }
      case 'theme11': {
        themeColor = '#008B8B';
        break;
      }
      case 'voilet': {
        themeColor = '#443266';
        break;
      }
      default: {
        //statements; 
        break;
      }
    }
    return themeColor;
  }
  loadSiteTopMenu(start) {
    //
    this.menuStart = start;
    this.menuEnd = start + 10;

    this.websiteService.getSiteTopMenu(this.menuStart, 10).subscribe(
      (data: any) => {
        this.websiteService.siteTopMenu = data;
        //alert(this.websiteService.siteTopMenu.TotalMenu);
        this.totalMenuItem = this.websiteService.siteTopMenu.TotalMenu;
      });
  }
  prevMenu() {
    this.menuIndex = this.menuIndex - 10;
    this.loadSiteTopMenu(this.menuIndex);
    if (this.menuIndex < 10) {
      document.getElementById("menuPrevButton").className = document.getElementById("menuPrevButton").className.concat(" d-none");
      document.getElementById("menuNextButton").className = document.getElementById("menuPrevButton").className.replace('d-none', '');
    }
  }
  nextMenu() {
    this.menuIndex = this.menuIndex + 10;
    this.loadSiteTopMenu(this.menuIndex);
    if (this.menuIndex + 10 > this.totalMenuItem) {
      document.getElementById("menuNextButton").className = document.getElementById("menuNextButton").className.concat(" d-none");
      document.getElementById("menuPrevButton").className = document.getElementById("menuPrevButton").className.replace('d-none', '');

    }
  }
  loadScrollingNews() {
    this.websiteService.getScrollingNews().subscribe(
      (data: any) => {
        this.websiteService.scrollingNews = data;
      });
  }
  loadSocailLinks() {
    //
    this.websiteService.getSocialLinks().subscribe(
      (data: any) => {
        this.websiteService.socialLinks = data;

        //this.fblink =data.LinkName;
      });
  }
  openPage(id, title, menuurl, parentID) {

    //this.router.navigate(['/details'], { queryParams: { pid: id} });

    if (title == 'Home' || title == 'প্রচ্ছদ') {
      this.router.navigate(['/home']);
    }
    else if (title.trim().toLowerCase() == 'student information' || title.trim().toLowerCase() == 'ছাত্র-ছাত্রী পরিচিতি') {
      this.router.navigate(['/studentsinformation']);
    }
    else if (title.trim().toLowerCase() == 'teacher information' || title.trim().toLowerCase() == 'শিক্ষক পরিচিতি' || title.trim().toLowerCase() == 'all employees') {
      this.router.navigate(['/teacherinformation']);
    }
    // else if(title.trim().toLowerCase() == 'executive directors'){
    //   this.router.navigate(['/managingcommittee'], { queryParams: { type: "MC01"}});
    //   //this.router.navigate(['my-route', 37], { queryParams: { type: "MC01"}});
    // }
    // else if(title.trim().toLowerCase() == 'governing body'){
    //   this.router.navigateByUrl('/managingcommittee?type=MC02');
    // }
    // else if(title.trim().toLowerCase() == 'assistant director'){
    //   this.router.navigateByUrl('/managingcommittee?type=MC03');
    // }
    else {
      if (parentID === undefined) {
        if (menuurl === title) {
          this.redirectTo('/details', id);
        }
      }
      else {
        if (menuurl == '') {
          this.redirectTo('/details', id);
        }
        else {
          if (menuurl.includes('http')) {
            window.open(menuurl, "_blank");
          }
          else {
            this.websiteService.pageTitle = title;
            this.router.navigateByUrl(menuurl);
          }

        }
      }

    }
  }
  redirectTo(uri, id) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri, { refresh: (new Date).getTime() }], { queryParams: { pid: id } }));

  }
  setIndex(index: number) {
    this.selectedIndex = index;
  }
  showNav() {
    if (this.isnavOpen) {

      document.getElementById("menu-main").className = document.getElementById("menu-main").className.replace('show', '');
      this.isnavOpen = false;
    }
    else {
      document.getElementById("menu-main").className = document.getElementById("menu-main").className.concat(" show");
      this.isnavOpen = true;
    }
  }
}
