import { Component,ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { WebsiteService } from '../shared/website.service';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.css']
})
export class PhotoGalleryComponent implements OnInit {
  selectedIndex: number = 0;
  startIndex : number = 0;
  googleLocationUrl;
  slideIndex = 1;
  items: GalleryItem[];
  gallerydata = [];
  imageData : any=this.loadGallery();//this.gallerydata;//this.loadGallery();
  constructor(public gallery: Gallery, public lightbox: Lightbox,public websiteService: WebsiteService) { }

  ngOnInit(): void {
    var modal = document.getElementById("myModal");
    //var btn = document.getElementById("myBtn");
    var span = document.getElementById("close");
    //btn.onclick = function() {
      //modal.style.display = "block";
    //};
    span.onclick = function() {
      modal.style.display = "none";
    }
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }
  }
  loadGallery(){
    this.websiteService.getAllGallery().subscribe(
      (data2 : any) =>{      
        for (let i=0; i<Object.keys(data2).length; i++) {
          this.gallerydata.push({
            srcUrl: data2[i].ImageUrl,
            previewUrl: data2[i].ImageUrl,
            title: data2[i].Title
          });
        }
        this.imageData = this.gallerydata;        
      });
      //this.loadGallery();
    this.items = this.imageData?.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
    /** Lightbox Example */

    // Get a lightbox gallery ref
    const lightboxRef = this.gallery.ref('lightbox');

    // Add custom gallery config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top
    });

    // Load items into the lightbox gallery ref
    lightboxRef.load(this.items);
  }
  openModal() {
    document.getElementById("myModalGallery").style.display = "block";
  }
  closeModal() {
    document.getElementById("myModalGallery").style.display = "none";
  }
  plusSlides(n) {
    this.showSlides(this.slideIndex += n);
  }
  
  currentSlide(n) {
    this.showSlides(this.slideIndex = n);
  }
  
   showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("demo");
    var captionText = document.getElementById("caption");
    if (n > slides.length) {this.slideIndex = 1}
    if (n < 1) {this.slideIndex = slides.length}
    for (i = 0; i < slides.length; i++) {
        const y = slides[i] as HTMLElement;
        y.style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
    }
    const z = slides[this.slideIndex-1] as HTMLElement;
    z.style.display = "block";
    dots[this.slideIndex-1].className += " active";
    const k = dots[this.slideIndex-1] as HTMLElement;
    //dots[this.slideIndex-1].getAttribute('alt');
    captionText.innerHTML = dots[this.slideIndex-1].getAttribute('alt');
  }
}
const data = [
  {
    srcUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg',
    previewUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg'
  },
  {
    srcUrl: 'https://preview.ibb.co/kPE1D6/clouds.jpg',
    previewUrl: 'https://preview.ibb.co/kPE1D6/clouds.jpg'
  },
  {
    srcUrl: 'https://preview.ibb.co/mwsA6R/img7.jpg',
    previewUrl: 'https://preview.ibb.co/mwsA6R/img7.jpg'
  },
  {
    srcUrl: 'https://preview.ibb.co/kZGsLm/img8.jpg',
    previewUrl: 'https://preview.ibb.co/kZGsLm/img8.jpg'
  }
];
