import { Component, OnInit } from '@angular/core';
import { WebsiteService } from '../shared/website.service';
import { Router } from '@angular/router';
import { HttpClient  } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-more-notice',
  templateUrl: './more-notice.component.html',
  styleUrls: ['./more-notice.component.css']
})
export class MoreNoticeComponent implements OnInit {

  constructor(private route: ActivatedRoute,private router: Router, public websiteService: WebsiteService,public http:HttpClient) { }

  ngOnInit() {
    this.loadNotice();
  }
loadNotice(){
  this.websiteService.getAllNotice().subscribe(
    (data : any) =>{       
      // 
      this.websiteService.allNotice= data;
    });
  }
  openPage(id){
    
    //this.router.navigate(['/details'], { queryParams: { pid: id} });
    this.redirectTo('/noticedetails',id);
  }
  redirectTo(uri,id) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([uri, {refresh: (new Date).getTime()}], { queryParams: { nid: id} }));    
  }
}
