import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { WebsiteService } from '../shared/website.service';
import { Router } from '@angular/router';
import { HttpClient  } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-teacher-information',
  templateUrl: './managing-committee.component.html',
  styleUrls: ['./managing-committee.component.css']
})
export class ManagingCommitteeComponent implements OnInit {
  term: string;
  allLoaded=0;
  totalTeacher=0;
  selectedTab='';
  constructor(private route: ActivatedRoute,private router: Router, public websiteService: WebsiteService,public http:HttpClient) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {  
      if(params['type']) {
        this.websiteService.managingCommitteeTypeCode = params['type'];
      }
      else {
        this.websiteService.managingCommitteeTypeCode = '';
        this.websiteService.pageTitle = 'All Members';
      }
    });
    this.loadAllManagingcommittee(this.websiteService.managingCommitteeTypeCode);
  }
  
  loadAllManagingcommittee(committeeType){
    this.websiteService.getManagingcommittee(committeeType).subscribe(
      (data : any) =>{        
        this.websiteService.managingcommitteeList = data;
        // if(data && data.length > 0)
        // {
        //   this.websiteService.managingCommitteeTypeName = this.websiteService.managingcommitteeList[0].TypeName;
        // }
      });
  }

  hideloader() {   
    // Setting display of spinner 
    // element to none 
    document.getElementById('loadingstatus').style.display = 'none'; 
} 
  showloader() {   
    // Setting display of spinner 
    // element to none 
    document.getElementById('loadingstatus').style.display = 'block'; 
  } 
  hideloaderBottom() {   
    // Setting display of spinner 
    // element to none 
    document.getElementById('loadingstatus2').style.display = 'none'; 
  } 
  showloaderBottom() {   
  // Setting display of spinner 
  // element to none 
  document.getElementById('loadingstatus2').style.display = 'block'; 
  } 
}
