import { Component, OnInit } from '@angular/core';
import { WebsiteService } from '../shared/website.service';
import { Router } from '@angular/router';
import { HttpClient  } from '@angular/common/http';
import { formatNumber } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  selectedIndex: number = 0;
  startIndex : number = 0;
  googleLocationUrl;
  slideIndex = 1;
  isAll: boolean= true;
  isImage: boolean= false;
  isAudio: boolean= false;
  isVideo: boolean= false;
  safeSrc: SafeResourceUrl;
  youtubeEmbedUrl: SafeResourceUrl;
  constructor(private router: Router, public websiteService: WebsiteService,public http:HttpClient,private sanitizer: DomSanitizer) 
  {     
    this.loadScrollingNews();
    this.loadHomeBanner();
    this.loadNotice();
    this.loadHomeMessages();    
    this.loadSchoolInfo();
    this.loadGallery();
    this.loadSchoolAttribute();
    this.loadVideo();
    //this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/c9F5kMUfFKk");
    //this.loadGoogleMapLocation();
    //this.youtubeEmbedUrl= this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed");
  }
  getYoutubeEmbedUrl(videoCode){
    //alert(videoCode);
    return this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+ videoCode);
  }
  //unescapeHtmlString(val){    
    //return unescape(val);
  //}
  ngOnInit() {
    this.loadPopupNotice();
    this.loadVisitorTrackingSummary();
    this.websiteService.startTimeNowTimer();
    var modal = document.getElementById("myModal");
    //var btn = document.getElementById("myBtn");
    var span = document.getElementById("close");
    //btn.onclick = function() {
      //modal.style.display = "block";
    //};
    span.onclick = function() {
      modal.style.display = "none";
    }
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }
    
  }
  loadPopupNotice(){
    this.websiteService.getPopupNotice().subscribe(
      (data : any) =>{       
        // 
        this.websiteService.popupNoticeList= data;
        var modal = document.getElementById("popupNotice");
        var closeBtn = document.getElementById("popupNoticeClose");
        closeBtn.onclick = function() {
          modal.style.display = "none";
        }
        if(data.length > 0){
          modal.style.display = "block";
          this.websiteService.popupNoticeTimer = setInterval(()=>{
            if(this.websiteService.noticeTimeLeft == 0){
              this.websiteService.noticeTimeLeft=0;
              clearInterval(this.websiteService.popupNoticeTimer);
              modal.style.display = "none";
            }
            else{
              this.websiteService.noticeTimeLeft--;
            }
          },1000);
        }
        else{
          modal.style.display = "none";
          clearInterval(this.websiteService.popupNoticeTimer);
        }
      });
  }
  showSlideImage(index){
    //alert(index);
    const slides = Array.from(document.getElementsByClassName('carousel-item'));
    //alert(slides.length);
    for(var i=0;i<slides.length;i++){
      document.getElementById("liImage"+i).className=document.getElementById("liImage"+i).className.replace('active','');
    }

    document.getElementById("liImage"+index).className = document.getElementById("liImage"+index).className.concat(" active");
    
    
    for (const x of slides) {
      const y = x as HTMLElement;
      y.style.display = 'none';
    }
    if (this.startIndex > slides.length - 1) {
      this.startIndex = 0;
      const slide = slides[index] as HTMLElement;
      slide.style.display = 'block';
      this.startIndex++;
    }
    else{
      this.startIndex = index;
      const slide = slides[this.startIndex] as HTMLElement;
      slide.style.display = 'block';
      this.startIndex++;
    }
  }
  Repeat() {
    setTimeout(() => {
      this.__FunctionSlide();
      this.Repeat();
    }, 5000);
  }
  __FunctionSlide() {
    const slides = Array.from(document.getElementsByClassName('carousel-item'));
    if (slides === []) {
      this.Repeat();
    }
    for (const x of slides) {
      const y = x as HTMLElement;
      y.style.display = 'none';
    }
    for(var i=0;i<slides.length;i++){
      document.getElementById("liImage"+i).className=document.getElementById("liImage"+i).className.replace('active','');
    }
    
    if (this.startIndex > slides.length - 1) {
      this.startIndex = 0;
      document.getElementById("liImage"+this.startIndex).className = document.getElementById("liImage"+this.startIndex).className.concat(" active");
      const slide = slides[this.startIndex] as HTMLElement;
      slide.style.display = 'block';
      this.startIndex++;
    } 
    else {
      document.getElementById("liImage"+this.startIndex).className = document.getElementById("liImage"+this.startIndex).className.concat(" active");
      const slide = slides[this.startIndex] as HTMLElement;
      slide.style.display = 'block';
      this.startIndex++;
    }
    
  }
  setIndex(index: number) {
    this.selectedIndex = index;
 }
 loadScrollingNews(){
  this.websiteService.getScrollingNews().subscribe(
    (data : any) =>{        
      this.websiteService.scrollingNews= data;
    });
}
  loadHomeBanner(){
    this.websiteService.getHomeBanner().subscribe(
      (data : any) =>{        
        this.websiteService.homeBanner= data;
        //this.__FunctionSlide();
        this.Repeat();
      });
  }
  loadVisitorTrackingSummary(){
    this.websiteService.getVisitorTrackingSummary().subscribe(
      (data : any) =>{        
        this.websiteService.visitorTrackingSummary= data;
      });
  }
  loadNotice(){
    this.websiteService.getNotice().subscribe(
      (data : any) =>{        
        this.websiteService.noticeBoard= data;
      });
  }
  loadHomeMessages(){
    this.websiteService.getHomeMessages().subscribe(
      (data : any) =>{        
        this.websiteService.homeMessages= data;
      });
  }
  loadSchoolInfo(){
    this.websiteService.getShcoolInfo().subscribe(
      (data : any) =>{        
        //
        this.websiteService.schoolInfo= data;
        //
        this.websiteService.systemUrl=data.SystemRediectUrl;
        localStorage.setItem('SystemRediectUrl',data.SystemRediectUrl);
      });
  }
  loadGallery(){
    this.websiteService.getGallery().subscribe(
      (data : any) =>{        
        this.websiteService.gallaryImages= data;
      });
  }
  
  loadSchoolAttribute(){
    this.websiteService.getSchoolAttributeByKey("").subscribe(
      (data : any) =>{        
        this.websiteService.schoolAttribute= data;
      });
  }
  loadVideo(){
    this.websiteService.getVideos().subscribe(
      (data : any) =>{        
        this.websiteService.videoList= data;
      });
  }
  openPage(id){
    
    //this.router.navigate(['/details'], { queryParams: { pid: id} });
    this.redirectTo('/noticedetails',id);
  }
  openMorePage(id){
    
    //this.router.navigate(['/details'], { queryParams: { pid: id} });
    this.redirectToMore('/moredetails',id);
  }
  redirectTo(uri,id) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([uri, {refresh: (new Date).getTime()}], { queryParams: { nid: id} }));    
  }
  redirectToMore(uri,id) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([uri, {refresh: (new Date).getTime()}], { queryParams: { id: id} }));    
  }
  openMessagePage(id){
    this.redirectToMore('/messagedetails',id);
  }
  calculateDiff(date: string){
    let d2: Date = new Date();
    let d1 = Date.parse(date); //time in milliseconds
    var timeDiff = d2.getTime() - d1;
    var diffHrs = Math.floor(timeDiff/ 3600 / 1000); // hours
    //var diff = timeDiff / (1000 * 3600 * 24);
    return diffHrs;
  }

  openModal() {
    document.getElementById("myModalGallery").style.display = "block";
  }
  closeModal() {
    document.getElementById("myModalGallery").style.display = "none";
  }
 
//showSlides(slideIndex);
 plusSlides(n) {
  this.showSlides(this.slideIndex += n);
}

currentSlide(n) {
  this.showSlides(this.slideIndex = n);
}

 showSlides(n) {
  var i;
  var slides = document.getElementsByClassName("mySlides");
  var dots = document.getElementsByClassName("demo");
  var captionText = document.getElementById("caption");
  if (n > slides.length) {this.slideIndex = 1}
  if (n < 1) {this.slideIndex = slides.length}
  for (i = 0; i < slides.length; i++) {
      const y = slides[i] as HTMLElement;
      y.style.display = "none";
  }
  for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
  }
  const z = slides[this.slideIndex-1] as HTMLElement;
  z.style.display = "block";
  dots[this.slideIndex-1].className += " active";
  const k = dots[this.slideIndex-1] as HTMLElement;
  //dots[this.slideIndex-1].getAttribute('alt');
  captionText.innerHTML = dots[this.slideIndex-1].getAttribute('alt');
}
onMediaChange(mediaType){
  this.isAll = false;
  this.isImage = false;
  this.isAudio = false;
  this.isVideo = false;
  if(mediaType == 'all'){
    this.isAll = true;
  }
  else if(mediaType == 'images'){
    this.isImage = true;
  }
  else if(mediaType == 'audio'){
    this.isAudio = true;
  }
  else if(mediaType == 'video'){
    this.isVideo = true;
  }
  else{
    this.isAll = true;
  }
}
}
