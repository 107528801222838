import { Component, OnInit } from '@angular/core';
import { WebsiteService } from '../shared/website.service';
import { Router } from '@angular/router';
import { HttpClient  } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-more-details',
  templateUrl: './more-details.component.html',
  styleUrls: ['./more-details.component.css']
})
export class MoreDetailsComponent implements OnInit {

  constructor(private route: ActivatedRoute,private router: Router, public websiteService: WebsiteService,public http:HttpClient) { }


  ngOnInit() {
    this.route.queryParams.subscribe(params => {      
      this.websiteService.attributeId=params['id'];
    });
    this.websiteService.getSchoolAttributeById(this.websiteService.attributeId).subscribe(
      (data : any) =>{       
        this.websiteService.moreInfo= data;
      });
  }

}
