<div class="container" #container>
	<div class="cards" [ngClass]="{'w-100': totalPages === 1}">
		<div class="row carousel-title" style="margin-top: 25px; margin-bottom: 15px; left: 20px;position: relative;">
			<h5>Trainings Accomplished :</h5><br/>
		</div>
		<div class="overflow" [ngStyle]="{'width': overflowWidth, 'left': pagePosition}">
			<div class="card" #card *ngFor="let i of dataArray; let index = index" [ngStyle]="{'width': cardWidth}">
				<mat-card class="traning" id="training-{{i}}">
					<mat-card-header>
					  <mat-card-title><h5>{{i.Title}}</h5></mat-card-title>
					  <mat-card-subtitle>
						<h6>{{i.InstitutionName}}</h6>
					  </mat-card-subtitle>
					</mat-card-header>
					<mat-card-content style="padding-top: 5px;border-top: 2px solid;border-color: #10c4d7;">
						<p>Duration: {{i.TrainingDuration}}</p>
						<p>Completion Year: {{i.TrainingYear}}</p>
					</mat-card-content>
				</mat-card>
			</div>
			
		</div>
		<div class="left" *ngIf="totalPages > 1" (click)="currentPage != 1 && changePage(-1)" [ngClass]="{'disabled':currentPage === 1}">
			<span><</span>
		</div>
		<div class="right" *ngIf="totalPages > 1" (click)="currentPage != totalPages && changePage(+1)"
			[ngClass]="{'disabled':currentPage === totalPages}"><span>></span>
		</div>
	</div>
	
</div>