<app-navbar></app-navbar>
<!-- Teacher Details Block -->
<div class="teacher-info-block">
    <div class="container">
            <div class="row"> 
                <ng-container *ngIf="!isEmptyObject(this.teacherDetails)">

                    <div class="container" >
                        <div class="row no-gutters" style="width: 100%;">
                            <h4 class="teacher-name">{{this.teacherDetails.FullName}}</h4>
                        </div>
                        <div class="row no-gutters" style="width: 100%;">
                            <span class="teacher-designation">{{this.teacherDetails.DesignationName}}</span><p>&nbsp; - ( {{this.teacherDetails.DepartmentName}} )</p>
                        </div>
                    </div>

                    <div class="container">
                    <div class="row no-gutters">
                        <div class="col-md-4 col-sm-12">
                            <div class="instructor-img">
                                <ng-container *ngIf="(isEmptyObject(this.teacherDetails.PhotoUrlWithPath))">
                                    <i class="fa fa-user fa-10x"></i>
                                </ng-container>
                                <ng-container *ngIf="(!isEmptyObject(this.teacherDetails.PhotoUrlWithPath))">
                                    <img src="{{this.teacherDetails.PhotoUrlWithPath}}" class="img-fluid" />
                                </ng-container>
                            </div>
                            <div class="row contact-social no-gutters" style="padding-top: 10px ;">
                                <i class="fa fa-phone fa-2x"></i>&nbsp;&nbsp;:&nbsp;
                                <a href=”tel:{{this.teacherDetails.Mobile}}″>{{this.teacherDetails.Mobile}}</a>
                            </div>
                            <div class="row contact-social no-gutters" >
                                <i class="fa fa-envelope fa-2x"></i>&nbsp;&nbsp;:&nbsp;
                                <a href=”email:{{this.teacherDetails.Email}}″>{{this.teacherDetails.Email}}</a>
                            </div>
                            <div class="contact-social d-flex align-items-center justify-content-center"  style="margin-top: 10px;">
                                <a href="{{this.teacherDetails.FBID}}" target="_blank">
                                    <i class="fab fa-facebook-f fa-2x"></i>
                                </a>
                                <a href="{{this.teacherDetails.LID}}" target="_blank" style="padding-left: 15px;">
                                    <i class="fab fa-linkedin fa-2x"></i>
                                </a>
                                <a href="{{this.teacherDetails.TID}}" target="_blank" style="padding-left: 15px;">
                                    <i class="fab fa-twitter fa-2x"></i>
                                </a>
                                <a href="{{this.teacherDetails.WID}}" target="_blank" style="padding-left: 15px;">
                                    <i class="fab fa-whatsapp fa-2x"></i>
                                </a>
                                <a href="{{this.teacherDetails.IID}}" target="_blank" style="padding-left: 15px;">
                                    <i class="fab fa-instagram fa-2x"></i>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-8  col-sm-12" style="padding-left: 10px;">
                            <div class="row no-gutters"> 
                                <p style="text-align: justify;">{{this.teacherDetails.BioObjective}}</p>
                            </div>
                            <div class="row no-gutters"> 
                                <div class="col-md-5" style="margin-top: 25px;">
                                    <h5>Education</h5>
                                    <ng-container *ngFor="let qualification of this.teacherDetails.EmployeeEducations">    
                                        <div class="education-info">
                                            <h6>{{qualification.InstituionName}} - ({{qualification.Country}})</h6>
                                            
                                            <div  style="font-weight: bold;color: #59658a;">
                                                <p>{{qualification.ExamName}}</p>
                                            </div>
                                            <div class="sub-text">
                                                <p>{{qualification.PassingYear}}</p>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="col-md-2">

                                </div>
                                <div class="col-md-5" style="margin-top: 25px;">
                                    <h5>Experience</h5>
                                    <ng-container *ngFor="let experience of this.teacherDetails.EmployeeExperiences">
                                        <div class="experience-info">
                                            <h6>{{experience.OrganizationName}}</h6>
                                            <p style="font-weight: bold;color: #59658a;">({{experience.Designition}})</p>
                                            <div class="sub-text">
                                                <p>{{experience.StartDate | date:'yyyy/MM/dd'}} - {{experience.EndDate | date:'yyyy/MM/dd'}}</p>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-container>
            </div>
    </div>
    <ng-container *ngIf="(!isEmptyObject(this.teacherDetails) && !isEmptyObject(this.teacherDetails.EmployeeTrainings))">
        <app-teacher-details-carousel [dataArray]="this.teacherDetails.EmployeeTrainings"></app-teacher-details-carousel>
    </ng-container>
</div>
<app-footer></app-footer>