import {Routes} from '@angular/router'
import {HomeComponent} from './home/home.component'
import {DetailsComponent} from './details/details.component'
import {MoreDetailsComponent} from './more-details/more-details.component'
import {MoreNoticeComponent} from './more-notice/more-notice.component'
import {NoticeDetailsComponent} from './notice-details/notice-details.component'
import {StudentsInformationComponent} from './students-information/students-information.component'
import {TeacherInformationComponent} from './teacher-information/teacher-information.component'
import {ManagingCommitteeComponent} from './managing-committee/managing-committee.component'
import {MessageDetailsComponent} from './message-details/message-details.component'
import {StudentresultComponent} from './studentresult/studentresult.component'
import {AcademicCalendarComponent} from './academic-calendar/academic-calendar.component'
import {TestFullcalendarComponent} from './test-fullcalendar/test-fullcalendar.component'
import {TestPhotogalleryComponent} from './test-photogallery/test-photogallery.component'
import {PhotoGalleryComponent} from './photo-gallery/photo-gallery.component'
import {TeacherDetailsComponent} from './teacher-details/teacher-details.component'

export const appRoutes : Routes = [
    {path:'home',component:HomeComponent},
    {path:'details',component:DetailsComponent},
    {path:'moredetails',component:MoreDetailsComponent},
    {path:'morenotice',component:MoreNoticeComponent},
    {path:'noticedetails',component:NoticeDetailsComponent},
    {path:'studentsinformation',component:StudentsInformationComponent},
    {path:'teacherinformation',component:TeacherInformationComponent},
    {path:'managingcommittee',component:ManagingCommitteeComponent},
    {path:'messagedetails',component:MessageDetailsComponent},
    {path:'result',component:StudentresultComponent},
    {path:'calendar',component:AcademicCalendarComponent},
    {path:'testcalendar',component:TestFullcalendarComponent},
    {path:'testphoto',component:TestPhotogalleryComponent},
    {path:'photogallery',component:PhotoGalleryComponent},
    {path:'teacherdetails/:EmployeeId',component:TeacherDetailsComponent},
    {path:'',redirectTo:'/home',pathMatch:'full'},
];