import { JsonpClientBackend } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/angular';
import { INITIAL_EVENTS, createEventId } from '../event-utils';
import { WebsiteService } from '../shared/website.service';

@Component({
  selector: 'app-academic-calendar',
  templateUrl: './academic-calendar.component.html',
  styleUrls: ['./academic-calendar.component.css']
})
export class AcademicCalendarComponent implements OnInit {
  calendarVisible = true;
  events =[]; 
  //calendarEvents: any=this.loadCalendarEvents();
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    initialView: 'dayGridMonth',    
    //themeSystem : 'bootstrap4',
    //themeSystem: 'bootstrap4',
    
    //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    //events: [
      //{ title: 'event 1', start: '2021-03-24', end:'2021-03-27' },
      //{ title: 'event 2', date: '2021-03-25' }
    //],
    //events: this.calendarEvents,
    weekends: true,
    //weekNumbers: true,
    //eventColor: 'red',
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    //select: this.handleDateSelect.bind(this),
    //eventClick: this.handleEventClick.bind(this),
    //eventsSet: this.handleEvents.bind(this)
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };
  constructor(public websiteService: WebsiteService) { }

  ngOnInit(): void {
    this.loadCalendarEvents();
  }
  loadCalendarEvents(){
    this.websiteService.getAllAcademicEvent().subscribe(
      (data) => {
        for (let i=0; i<Object.keys(data).length; i++) {
          this.events.push({
              title: data[i].title,
              start: data[i].start,
              end: data[i].end,
              allDay :(data[i].start == data[i].end? true: false),
              //date: data[i].start
          });
          
      }
      
      //this.calendarEvents = data;
      this.calendarOptions.events =this.events;
    });
  } 

}
