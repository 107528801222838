<app-navbar></app-navbar>
<div style="border:solid 6px whitesmoke;background-color: gray;">
<div class="container site-content">
    <div class="row">
        <div class="col col-md-12">
            <div class="card box-shadow">
                <div class="card-header header-shadow">
                    <b>Photo Gallery</b>
                </div>
                <div class="card-body">
                    <div class="basic-container" style="display: none;">
                        <h2>Basic Example</h2>
                        <gallery id="basic-test" fluid [items]="items"></gallery>
                    </div>
                    <div class="gallerize-example">
                        <!-- <h2>Gallerize Example</h2> -->
                        <p class="container">
                    <ng-container *ngFor="let img of imageData;let i = index">
                        <img width="150" height="150" style="margin-right: 5px; margin-bottom: 5px;" (click)="openModal();currentSlide(i+1)" src="{{img?.srcUrl.toString().replace('Uploads',websiteService.apiimagePath)}}" alt="{{img.title}}" class="img-fluid2">
                           
                    </ng-container>
                   </p>
                    </div>
                    <!-- <div class="gallerize-example">
                        <p class="container" gallerize>
                            <img width="250" height="250" style="margin-right: 5px; margin-bottom: 5px;" *ngFor="let img of imageData" [src]="img?.srcUrl.toString().replace('Uploads',websiteService.apiimagePath)">
                        </p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<div id="myModalGallery" class="modal2">
                
    <div class="modal-content">
        <div class="modal-header" [ngStyle]="{'background-color': this.websiteService.themeColor}">
            <label>Gallery</label>
            <span class="close cursor" (click)="closeModal()">&times;</span>
        </div>
        <div class="modal-body">
        <ng-container *ngFor="let img of imageData;let i = index">
            <div class="mySlides">
                <div class="numbertext">{{i+1}} / {{this.imageData.length}}</div>
                <img class="demo" src="{{img?.srcUrl.toString().replace('Uploads',websiteService.apiimagePath)}}"  alt="{{img.title}}" style="width:100%">
              </div>
        </ng-container>
      
      <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
      <a class="next" (click)="plusSlides(1)">&#10095;</a>
  
      <div class="caption-container">
        <p id="caption"></p>
      </div>
      <!-- <ng-container *ngFor="let item of websiteService.gallaryImages;let i = index">
        <div class="column">
            <img class="demo cursor" src="{{item.ImageUrl.toString().replace('Uploads',websiteService.apiimagePath)}}" style="width:100%" (click)="currentSlide(i+1)"  alt="{{item.Title}}">
          </div>
        </ng-container> -->
    </div>
    </div>
  </div>
  
<app-footer></app-footer>