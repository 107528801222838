<app-navbar></app-navbar>
<div class="governing-block">
    <div class="governing-block">
        <!-- <div class="container">
            <div class="title text-center my-3">
                <h2>Message From Governing Body Member</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, id minus? Porro itaque vero nisi
                    suscipit dicta officia a quo alias, distinctio voluptatem delectus tenetur, officiis, voluptates iste
                    sunt numquam!</p>
            </div>
        </div> -->
        <ul class="governing-items mt-3 mt-sm-5">
            <li class="item">
                <span></span>
                <div class="item-bg">
                    <img class="img-fluid" *ngIf="this.websiteService.homeMessageDetails?.Title.toString().toLowerCase().indexOf('principal')>=0" src="./assets/assets/img/governingBody/02.png" alt="message from">
                    <img class="img-fluid" *ngIf="this.websiteService.homeMessageDetails?.Title.toString().toLowerCase().indexOf('vice-principal')>=0" src="./assets/assets/img/governingBody/01.png" alt="message from">
                    <img class="img-fluid" *ngIf="this.websiteService.homeMessageDetails?.Title.toString().toLowerCase().indexOf('chairmen')>=0" src="./assets/assets/img/governingBody/03.png" alt="message from">
                </div>
                <div class="container">
                    <div
                        class="member-info d-flex flex-wrap align-items-end justify-content-center justify-content-md-start">
                        <div class="thumb">
                            <div class="thumb-wrap">
                                <img class="img-fluid" src="{{this.websiteService.homeMessageDetails?.ImageUrl.toString().replace('Uploads',websiteService.apiimagePath).replace('thumb-','')}}" alt="Chairman">
                            </div>
                        </div>
                        <div class="name-dsignation mb-sm-5">
                            <div class="designation">
                                {{this.websiteService.homeMessageDetails?.Title.toString()}}
                            </div>
                            <div class="name">
                                {{this.websiteService.homeMessageDetails?.AboutTitle.toString()}}
                            </div>
                        </div>
                    </div>
    
                    <div class="member-quote">
                        <p class="quote-text px-4 px-sm-5 mt-3 mt-sm-5" [innerHtml]="this.websiteService.unescapeHtmlString(this.websiteService.homeMessageDetails?.Message)  | safe: 'html'">
                            
                        </p>
                    </div>
                    <div *ngIf="this.websiteService.homeMessageDetails.About != null && this.websiteService.homeMessageDetails?.About !=''">
                        <h2 style="padding-top:20px;">About</h2>
                        <div class="member-quote">
                            <p class="quote-text px-4 px-sm-5 mt-3 mt-sm-5"
                                [innerHtml]="this.websiteService.unescapeHtmlString(this.websiteService.homeMessageDetails?.About)  | safe: 'html'">
                    
                            </p>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
<!-- <div style="border:solid 6px whitesmoke;background-color: gray;">
    <div class="container site-content">
        <div class="row">
            <div class="col col-md-12">
                <div class="card box-shadow">
                    <div class="card-header header-shadow">
                        <b>Message</b>
                    </div>
                    <div class="card-body">
                        <div
                            [innerHtml]="this.websiteService.unescapeHtmlString(this.websiteService.homeMessageDetails?.Message)  | safe: 'html'">
                        </div>
                    </div>
                    <div class="card-header header-shadow">
                        <b>About</b>
                    </div>
                    <div class="d-flex card-body">
                        <div class="info">
                            <img style="padding: 10px;" class="img-fluid"
                                src="{{this.websiteService.homeMessageDetails?.ImageUrl.toString().replace('Uploads',websiteService.apiimagePath)}}"
                                alt="Chairman">
                            <p
                                [innerHtml]="this.websiteService.unescapeHtmlString(this.websiteService.homeMessageDetails?.About)  | safe: 'html'">

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<app-footer></app-footer>
