import { Component, OnInit } from '@angular/core';
import { WebsiteService } from '../shared/website.service';
import { Router } from '@angular/router';
import { HttpClient  } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-message-details',
  templateUrl: './message-details.component.html',
  styleUrls: ['./message-details.component.css']
})
export class MessageDetailsComponent implements OnInit {

  constructor(private route: ActivatedRoute,private router: Router, public websiteService: WebsiteService,public http:HttpClient) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {      
      this.websiteService.homeMessageId=params['id'];
    });
    this.websiteService.getHomeMessageById(this.websiteService.homeMessageId).subscribe(
      (data : any) =>{      
         
        this.websiteService.homeMessageDetails= data;
        
      });
  }
}
