<app-navbar></app-navbar>
<!-- Notice Block -->
<div class="notice-block">
    <div class="notice-info">
        <div class="container">
            <div class="title text-left">
                <h2>Notices</h2>
                <span></span>
            </div>
        </div>
    </div>
    <ng-container *ngFor="let item of websiteService.allNotice;let i = index">
        <div class="notice-messages" *ngIf="i===0">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1">
                        <div class="message text-center">
                            <div class="icon rounded-circle d-flex align-items-center justify-contents-center mx-auto p-4">
                                <img class="img-fluid" src="./assets/assets/img/noticeIcon.png" alt="Announce">
                            </div>
                            <div class="date py-2">
                                <i class="far fa-calendar-alt"></i> {{item.NoticePublishDate | date :'dd/MM/yy'}}
                            </div>
                            <p [innerHtml]="this.websiteService.unescapeHtmlString(item.NoticeTitile)  | safe: 'html'"></p>
                            <a style="cursor: pointer;" (click)="openPage(item.NoticeID)"  class="btn btn-danger mt-3">Show Details...</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    
</div>

<div class="notices-body">
    <div class="container">
        <div class="ntc-wrap">
            <div class="row">
               <div class="col-xl-2 col-md-4 py-2">
                    <div class="ntc-title">
                        <!-- <h2>Notices From</h2> -->
                    </div>
                    <ul class="nav nav-tabs flex-sm-column" id="v-pills-tab" role="tablist">
                        <!-- <li>
                            <a class="nav-link active" data-toggle="pill" href="#ntcChairman" role="tab">Notices From Chairman</a>
                        </li>
                        <li>
                            <a class="nav-link" data-toggle="pill" href="#ntcPrincipal" role="tab" >Notices From Principal</a>
                        </li>
                        <li>
                            <a class="nav-link" data-toggle="pill" href="#ntcAdmission" role="tab" >Notices From Admission</a>
                        </li>
                        <li>
                            <a class="nav-link" data-toggle="pill" href="#ntcAccounts" role="tab">Notices From Account</a>
                        </li>
                        <li>
                            <a class="nav-link" data-toggle="pill" href="#ntcExaminer" role="tab">Notices From Examiner</a>
                        </li> -->
                    </ul>
                </div>
                <div class="col-xl-9 col-md-8 py-2">
                    <div class="ntc-title">
                        <h2>Notices</h2>
                    </div>
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="ntcChairman" role="tabpanel">
                            <ul class="ntc-items">
                                <ng-container *ngFor="let item of websiteService.allNotice;let i = index">
                                    <li class="item d-flex align-items-center" *ngIf="i !=0">
                                        <div class="announce-icon rounded-circle d-flex align-items-center justify-contents-center p-4 mr-4">
                                            <img class="img-fluid" src="./assets/assets/img/noticeIcon.png" alt="Announce">
                                        </div>
    
                                        <div class="announce-info">
                                            <p [innerHtml]="this.websiteService.unescapeHtmlString(item.NoticeTitile)  | safe: 'html'"></p>
                                            <div class="dt-lrn-more d-flex flex-wrap align-items-center mt-2">
                                                <div class="date py-2">
                                                    <i class="far fa-calendar-alt"></i>{{item.NoticePublishDate | date :'dd/MM/yy'}}
                                                </div>
    
                                                <a style="cursor: pointer;" (click)="openPage(item.NoticeID)" class="d-inline-block ml-sm-auto py-2">Show Details</a>
                                            </div>
                                        </div>
                                    </li>
                                </ng-container>
                            </ul>

                            <div class="load-more text-center">
                                <button class="btn btn-danger mt-4">Load More..</button>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="ntcPrincipal" role="tabpanel">
                            <ul class="ntc-items">
                                <li class="item d-flex align-items-center">
                                    <div class="announce-icon rounded-circle d-flex align-items-center justify-contents-center p-4 mr-4">
                                        <img class="img-fluid" src="./assets/assets/img/noticeIcon.png" alt="Announce">
                                    </div>

                                    <div class="announce-info">
                                        <p>গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ...গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ... </p>
                                        <div class="dt-lrn-more d-flex flex-wrap align-items-center mt-2">
                                            <div class="date py-2">
                                                <i class="far fa-calendar-alt"></i> 02-05-2020
                                            </div>

                                            <a href="#" class="d-inline-block ml-sm-auto py-2">Learn More</a>
                                        </div>
                                    </div>
                                </li>

                                <li class="item d-flex align-items-center">
                                    <div class="announce-icon rounded-circle d-flex align-items-center justify-contents-center p-4 mr-4">
                                        <img class="img-fluid" src="./assets/assets/img/noticeIcon.png" alt="Announce">
                                    </div>

                                    <div class="announce-info">
                                        <p>গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ...গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ... </p>
                                        <div class="dt-lrn-more d-flex flex-wrap align-items-center mt-2">
                                            <div class="date py-2">
                                                <i class="far fa-calendar-alt"></i> 02-05-2020
                                            </div>

                                            <a href="#" class="d-inline-block ml-sm-auto py-2">Learn More</a>
                                        </div>
                                    </div>
                                </li>

                                <li class="item d-flex align-items-center">
                                    <div class="announce-icon rounded-circle d-flex align-items-center justify-contents-center p-4 mr-4">
                                        <img class="img-fluid" src="./assets/assets/img/noticeIcon.png" alt="Announce">
                                    </div>

                                    <div class="announce-info">
                                        <p>গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ...গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ... </p>
                                        <div class="dt-lrn-more d-flex flex-wrap align-items-center mt-2">
                                            <div class="date py-2">
                                                <i class="far fa-calendar-alt"></i> 02-05-2020
                                            </div>

                                            <a href="#" class="d-inline-block ml-sm-auto py-2">Learn More</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <div class="load-more text-center">
                                <button class="btn btn-danger mt-4">Load More..</button>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="ntcAdmission" role="tabpanel">
                            <ul class="ntc-items">
                                <li class="item d-flex align-items-center">
                                    <div class="announce-icon rounded-circle d-flex align-items-center justify-contents-center p-4 mr-4">
                                        <img class="img-fluid" src="./assets/assets/img/noticeIcon.png" alt="Announce">
                                    </div>

                                    <div class="announce-info">
                                        <p>গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ...গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ... </p>
                                        <div class="dt-lrn-more d-flex flex-wrap align-items-center mt-2">
                                            <div class="date py-2">
                                                <i class="far fa-calendar-alt"></i> 02-05-2020
                                            </div>

                                            <a href="#" class="d-inline-block ml-sm-auto py-2">Learn More</a>
                                        </div>
                                    </div>
                                </li>

                                <li class="item d-flex align-items-center">
                                    <div class="announce-icon rounded-circle d-flex align-items-center justify-contents-center p-4 mr-4">
                                        <img class="img-fluid" src="./assets/assets/img/noticeIcon.png" alt="Announce">
                                    </div>

                                    <div class="announce-info">
                                        <p>গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ...গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ... </p>
                                        <div class="dt-lrn-more d-flex flex-wrap align-items-center mt-2">
                                            <div class="date py-2">
                                                <i class="far fa-calendar-alt"></i> 02-05-2020
                                            </div>

                                            <a href="#" class="d-inline-block ml-sm-auto py-2">Learn More</a>
                                        </div>
                                    </div>
                                </li>

                                <li class="item d-flex align-items-center">
                                    <div class="announce-icon rounded-circle d-flex align-items-center justify-contents-center p-4 mr-4">
                                        <img class="img-fluid" src="./assets/assets/img/noticeIcon.png" alt="Announce">
                                    </div>

                                    <div class="announce-info">
                                        <p>গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ...গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ... </p>
                                        <div class="dt-lrn-more d-flex flex-wrap align-items-center mt-2">
                                            <div class="date py-2">
                                                <i class="far fa-calendar-alt"></i> 02-05-2020
                                            </div>

                                            <a href="#" class="d-inline-block ml-sm-auto py-2">Learn More</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <div class="load-more text-center">
                                <button class="btn btn-danger mt-4">Load More..</button>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="ntcAccounts" role="tabpanel">
                            <ul class="ntc-items">
                                <li class="item d-flex align-items-center">
                                    <div class="announce-icon rounded-circle d-flex align-items-center justify-contents-center p-4 mr-4">
                                        <img class="img-fluid" src="./assets/assets/img/noticeIcon.png" alt="Announce">
                                    </div>

                                    <div class="announce-info">
                                        <p>গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ...গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ... </p>
                                        <div class="dt-lrn-more d-flex flex-wrap align-items-center mt-2">
                                            <div class="date py-2">
                                                <i class="far fa-calendar-alt"></i> 02-05-2020
                                            </div>

                                            <a href="#" class="d-inline-block ml-sm-auto py-2">Learn More</a>
                                        </div>
                                    </div>
                                </li>

                                <li class="item d-flex align-items-center">
                                    <div class="announce-icon rounded-circle d-flex align-items-center justify-contents-center p-4 mr-4">
                                        <img class="img-fluid" src="./assets/assets/img/noticeIcon.png" alt="Announce">
                                    </div>

                                    <div class="announce-info">
                                        <p>গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ...গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ... </p>
                                        <div class="dt-lrn-more d-flex flex-wrap align-items-center mt-2">
                                            <div class="date py-2">
                                                <i class="far fa-calendar-alt"></i> 02-05-2020
                                            </div>

                                            <a href="#" class="d-inline-block ml-sm-auto py-2">Learn More</a>
                                        </div>
                                    </div>
                                </li>

                                <li class="item d-flex align-items-center">
                                    <div class="announce-icon rounded-circle d-flex align-items-center justify-contents-center p-4 mr-4">
                                        <img class="img-fluid" src="./assets/assets/img/noticeIcon.png" alt="Announce">
                                    </div>

                                    <div class="announce-info">
                                        <p>গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ...গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ... </p>
                                        <div class="dt-lrn-more d-flex flex-wrap align-items-center mt-2">
                                            <div class="date py-2">
                                                <i class="far fa-calendar-alt"></i> 02-05-2020
                                            </div>

                                            <a href="#" class="d-inline-block ml-sm-auto py-2">Learn More</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <div class="load-more text-center">
                                <button class="btn btn-danger mt-4">Load More..</button>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="ntcExaminer" role="tabpanel">
                            <ul class="ntc-items">
                                <li class="item d-flex align-items-center">
                                    <div class="announce-icon rounded-circle d-flex align-items-center justify-contents-center p-4 mr-4">
                                        <img class="img-fluid" src="./assets/assets/img/noticeIcon.png" alt="Announce">
                                    </div>

                                    <div class="announce-info">
                                        <p>গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ...গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ... </p>
                                        <div class="dt-lrn-more d-flex flex-wrap align-items-center mt-2">
                                            <div class="date py-2">
                                                <i class="far fa-calendar-alt"></i> 02-05-2020
                                            </div>

                                            <a href="#" class="d-inline-block ml-sm-auto py-2">Learn More</a>
                                        </div>
                                    </div>
                                </li>

                                <li class="item d-flex align-items-center">
                                    <div class="announce-icon rounded-circle d-flex align-items-center justify-contents-center p-4 mr-4">
                                        <img class="img-fluid" src="./assets/assets/img/noticeIcon.png" alt="Announce">
                                    </div>

                                    <div class="announce-info">
                                        <p>গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ...গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ... </p>
                                        <div class="dt-lrn-more d-flex flex-wrap align-items-center mt-2">
                                            <div class="date py-2">
                                                <i class="far fa-calendar-alt"></i> 02-05-2020
                                            </div>

                                            <a href="#" class="d-inline-block ml-sm-auto py-2">Learn More</a>
                                        </div>
                                    </div>
                                </li>

                                <li class="item d-flex align-items-center">
                                    <div class="announce-icon rounded-circle d-flex align-items-center justify-contents-center p-4 mr-4">
                                        <img class="img-fluid" src="./assets/assets/img/noticeIcon.png" alt="Announce">
                                    </div>

                                    <div class="announce-info">
                                        <p>গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ...গত ২৪ ঘণ্টায় ১৫ হাজার ৭৭২ জনের নমুনা পরীক্ষা করা হয়েছে। এই সময় ... </p>
                                        <div class="dt-lrn-more d-flex flex-wrap align-items-center mt-2">
                                            <div class="date py-2">
                                                <i class="far fa-calendar-alt"></i> 02-05-2020
                                            </div>

                                            <a href="#" class="d-inline-block ml-sm-auto py-2">Learn More</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <div class="load-more text-center">
                                <button class="btn btn-danger mt-4">Load More..</button>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
