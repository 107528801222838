<!-- Header -->
<ng-template #loginDialog>
    <h2 matDialogTitle>No Internet!!</h2>
    <p matDialogContent><em>Sorry your internet connectivity is not available! Please try again after your internet
            connection is ok.</em></p>
    <mat-dialog-actions align="end">
        <button mat-button matDialogClose>Dismiss</button>
    </mat-dialog-actions>
</ng-template>
<!-- The Modal -->
<div id="myModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content">
        <button class="close" id="close">&times;</button>
        <div id="dvCircular">
            <ul>
                <ng-container *ngFor="let item of websiteService.circularInfo;let i = index">
                    <li>
                        <a *ngIf="item.CircularType == 'Recruitment'"
                            href="http://{{this.websiteService.systemUrl}}/Recruitment/RecruitmentForm.aspx?cid={{item.AdmissionCerculerID}}"
                            target="_self">{{item.AdmissionTag}}</a>
                        <a *ngIf="item.CircularType == 'Admission' && item.IsBoardSelected == false"
                            href="http://{{this.websiteService.systemUrl}}/Admission/AdmissionForm.aspx?cid={{item.AdmissionCerculerID}}"
                            target="_self">{{item.AdmissionTag}}</a>
                        <a *ngIf="item.CircularType == 'Admission' && item.IsBoardSelected == true"
                            (click)="showBoardSelectedModal(item.AdmissionCerculerID)"
                            style="cursor: pointer;">{{item.AdmissionTag}}</a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>

</div>

<!-- The Modal -->
<div id="boardSelectedModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content">
        <button class="close" id="closeBoardSelectedModal">&times;</button>
        <div>
            <ul style="padding: 20px;">
                <ng-container *ngFor="let item of websiteService.circularInfo;let i = index">
                    <li style="list-style-type: square;cursor: pointer;">
                        <a *ngIf="item.CircularType == 'Admission' && item.IsBoardSelected == true"
                            (click)="showBoardSelectedModal(item.AdmissionCerculerID)"
                            style="cursor: pointer;">{{item.AdmissionTag}}</a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>

</div>
<div id="boardSelectedModalInput" class="modal">

    <!-- Modal content -->
    <div class="modal-content">
        <button class="close" id="closeBoardSelectedModalInput">&times;</button>
        <div>
            <input type="hidden" id="hdnCircularID" />
            Board Roll:
            <input type="text" id="txtBoardRoll" (input)="onChangeRoll($event)" />&nbsp;&nbsp;
            <input class="btn btn-primary" type="button" value="Submit" (click)="submitAdmission()" />
        </div>
    </div>

</div>
<div id="applicanProfileWin" class="modal">

    <!-- Modal content -->
    <div class="modal-content">
        <button class="close" id="closeapplicanProfileWin">&times;</button>
        <div>
            Admission Roll:
            <input type="text" id="txtBoardRoll" (input)="onChangeRollApplicantProfile($event)" />&nbsp;&nbsp;
            <input class="btn btn-primary" type="button" value="Submit" (click)="submitapplicanProfile()" />
        </div>
    </div>

</div>
<div class="header-block">
    <div class="top" [ngStyle]="{'background-color': this.websiteService.themeColor}">

        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-7 py-2 py-md-4">
                    <div class="school-info-thumb">
                        <a href="/">
                            <img class="img-fluid" style="max-height: 110px;"
                                src="{{websiteService.schoolInfo?.HomeLogoUrl.replace('Uploads',websiteService.apiimagePath)}}"
                                alt="School">
                        </a>

                    </div>
                </div> <!-- /.col -->
                <div class="col-md-5 text-center text-md-right py-2 py-md-4">
                    <div class="login-social">
                        <a style="margin: 5px;" id="btnPrintProfile" class="btn btn-primary" (click)="printProfileWin()">Print
                            Profile</a>
                        <a id="btnApplyNow" style="margin: 5px; display: none;" class="btn btn-primary">Apply Now</a>
                        <button style="margin: 5px;" class="btn btn-primary" (click)="openDialog()">Login</button>
                        <a style="margin: 5px;" id="btnApplyNowBoardSelected" *ngIf="isBoardSelected" class="btn btn-primary"
                            (click)="showBoardSelectedWin()">Apply Now</a>
                        <div class="d-block">
                            <div class="social-buttons">
                                <ng-container *ngFor="let item of websiteService.socialLinks;let i = index">
                                    <a class="social-buttons__button social-button social-button--facebook" *ngIf="item.LinkName === 'facebook'"
                                        [href]="item.LinkUrl | safe : 'resourceUrl'"><i
                                            class="fab fa-facebook-f"></i></a>
                                    <a class="social-buttons__button social-button social-button--twitter" *ngIf="item.LinkName === 'twitter'"
                                        [href]="item.LinkUrl | safe : 'resourceUrl'"><i class="fab fa-twitter"></i></a>
                                    <a  class="social-buttons__button social-button social-button--youtube" *ngIf="item.LinkName === 'youtube'"
                                        [href]="item.LinkUrl | safe : 'resourceUrl'"><i class="fab fa-youtube"></i></a>
                                    <a class="social-buttons__button social-button social-button--linkedin" *ngIf="item.LinkName === 'linkedin'"
                                        [href]="item.LinkUrl | safe : 'resourceUrl'"><i class="fab fa-linkedin"></i></a>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div> <!-- /.col -->
            </div> <!-- /.row -->
        </div>
    </div>
    <!-- Menu -->
    <div class="navigation-menu" [ngStyle]="{'background': this.websiteService.navigationColor}">
        <div class="container">
            <nav class="navbar navbar-expand-md">
                <a class="navbar-brand d-block d-md-none" href="#">
                    <!-- <img class="img-fluid" src="{{websiteService.schoolInfo?.HomeLogoUrl.replace('Uploads',websiteService.apiimagePath)}}" alt="Logo Brand"> -->
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#expnand-menu"
                    aria-expanded="false" (click)="showNav()">
                    <i class="fas fa-bars"></i>
                </button>
                <div id="menu-main" class="collapse navbar-collapse menu-wrapper">
                    <ul class="navbar-nav w-100 justify-content-between mt-4 mt-md-0 menu">
                        <li class="nav-item">
                            <a class="nav-link" id="menuPrevButton" href="javascript:" (click)="prevMenu()">
                                <i class="fas fa-chevron-left"></i>
                            </a>
                        </li>
                        <ng-container *ngFor="let item of websiteService.siteTopMenu?.SiteTopMenuAct;let i = index">
                            <li class="nav-item item" [class.active]="selectedIndex === i"
                                [class.dropdown]="item.ChildMenu.length>0" (click)="setIndex(i)">
                                <a class="nav-link" [class.dropdown-toggle]="item.ChildMenu.length>0"
                                    style="cursor: pointer;"
                                    (click)="openPage(item.ParentMenu.ID,item.ParentMenu.Title,item.ParentMenu.NavigateUrl, item.ParentID)">{{item.ParentMenu.Title}}</a>
                                <ul class="dropdown-menu" *ngIf="item.ChildMenu.length>0">
                                    <ng-container *ngFor="let item2 of item.ChildMenu;let j = index">
                                        <li class="nav-item">
                                            <a class="nav-link" style="cursor: pointer;"
                                                (click)="openPage(item2.ID,item2.Title,item2.NavigateUrl, item2.ParentID)">{{item2.Title}}</a>
                                        </li>
                                    </ng-container>
                                </ul>
                            </li>
                            <!-- <li class="nav-item" *ngIf="websiteService.siteTopMenu.length>6 && i==6">
                            <a class="nav-link" href="#" *ngIf="websiteService.siteTopMenu.length>6 && i==6"><i class="fas fa-chevron-right"></i></a>
                            <ul class="dropdown-menu" *ngIf="websiteService.siteTopMenu.length>6">
                                <li class="nav-item">
                                    <a href="#" class="nav-link">{{item.ParentMenu.Title}}</a>
                                </li>
                              </ul>
                        </li> -->
                        </ng-container>
                        <li class="nav-item">
                            <a class="nav-link" id="menuNextButton" href="javascript:" (click)="nextMenu()">
                                <i class="fas fa-chevron-right"></i>
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                        <a class="nav-link" href="#"><i class="fas fa-chevron-right"></i></a>
                        <ul class="dropdown-menu">
                            <ng-container *ngFor="let item of websiteService.siteTopMenu.SiteTopMenuMoreItem;let i = index">
                    
                    
                                <li class="nav-item">
                                    <a style="cursor: pointer;" class="nav-link"
                                        (click)="openPage(item.ParentMenu.ID,item.ParentMenu.Title,item.ParentMenu.NavigateUrl)"><i
                                            class="fas fa-chevron-left" *ngIf="item.ChildMenu.length>0"></i>{{item.ParentMenu.Title}}</a>
                                    <ul class="sub-menu" *ngIf="item.ChildMenu.length>0">
                                        <ng-container *ngFor="let item2 of item.ChildMenu;let j = index">
                                            <li class="nav-item">
                                                <a class="nav-link" (click)="openPage(item2.ID,item2.Title,item2.NavigateUrl)"
                                                    style="cursor: pointer;">{{item2.Title}}</a>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </li>
                    
                    
                            </ng-container>
                        </ul>
                    </li>  -->
                    </ul>
                </div>
            </nav>

        </div>
    </div>
</div>