<app-navbar></app-navbar>
<div style="border:solid 6px whitesmoke;background-color: gray;">
    <div class="container site-content">
        <div class="row">
            <div class="col col-md-12">
                <div class="card box-shadow">
                    <div class="card-header header-shadow">
                        <b>Academic Calendar</b>
                        <!-- <b>{{this.websiteService.moreInfo.Title}}</b> -->
                    </div>
                    <div class="card-body">
                        <full-calendar
                        *ngIf='calendarVisible'
                        [options]='calendarOptions'
                      ></full-calendar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  <app-footer></app-footer>