import { Component, OnInit } from '@angular/core';
import { WebsiteService } from '../shared/website.service';
import { Router } from '@angular/router';
import { HttpClient  } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
//import * as jspdf from 'jspdf';
import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-studentresult',
  templateUrl: './studentresult.component.html',
  styleUrls: ['./studentresult.component.css']
})
export class StudentresultComponent implements OnInit {
  
  constructor(private route: ActivatedRoute,private router: Router, public websiteService: WebsiteService,public http:HttpClient) 
  { 
    this.websiteService.IsFailedAnySubject = false;
  }
  selectedAcademicyearId;
  selectedClassId;
  selectedGroupId;
  selectedExamId;
  stdcode : string;
  subjectCounter;
  selectedAcademicYear;
  selectedExam;
  selectedExamType;
  
  ngOnInit(): void {
    this.hideloader();
    this.loadAcademicYear();
    this.loadClass();
    //this.getResult();
    this.subjectCounter =0;
    //this.IsFailedAnySubject=false;
  }
  hideloader() {   
    // Setting display of spinner 
    // element to none 
    document.getElementById('loadingstatus').style.display = 'none'; 
} 
showloader() {   
  // Setting display of spinner 
  // element to none 
  document.getElementById('loadingstatus').style.display = 'block'; 
} 
  loadAcademicYear(){
    this.websiteService.getAcademicYear().subscribe(
      (data : any) =>{        
        this.websiteService.academicYear= data;
      });
  }
  academicYearChange(event){
    const value = event.target.value;
    //
    let selectElementText = event.target['options'][event.target['options'].selectedIndex].text;
    this.selectedAcademicYear = selectElementText;
    this.selectedAcademicyearId = value;
    this.loadExam();
  }
  loadClass(){
    this.websiteService.getClass().subscribe(
      (data : any) =>{        
        this.websiteService.classList= data;
      });
  }
  loadGroup(){
    this.websiteService.getGroup().subscribe(
      (data : any) =>{        
        this.websiteService.groupList= data;
      });
  }
  classChange(event){
    const value = event.target.value;
    this.selectedClassId = value;
    this.websiteService.getClassById(value).subscribe(
      (data : any) =>{        
        //this.websiteService.groupList= data;
        //alert(data.IsGroup);
        //alert(this.selectedAcademicyearId);
        this.loadExam();
        if(data.IsGroup == true){
          this.loadGroup();
          this.showGroup();
        }
        else{
          this.hideGroup();
        }
      });
    //alert(value);
  }
  showGroup() {   
    // Setting display of spinner 
    // element to none 
    document.getElementById('dvGroup').style.display = 'block'; 
  } 
  hideGroup() {   
    // Setting display of spinner 
    // element to none 
    document.getElementById('dvGroup').style.display = 'none'; 
  } 
  loadExam(){
    var aid = 0;
    var clsid=0;
    var groupid =0;
    if(this.selectedAcademicyearId != undefined){
      aid = this.selectedAcademicyearId;
    }
    if(this.selectedClassId != undefined){
      clsid = this.selectedClassId;
    }
    if(this.selectedGroupId != undefined){
      groupid = this.selectedGroupId;
    }
    //alert('aid:'+ aid +';clsid:'+clsid+';groupid:'+ groupid);
    this.websiteService.getPublishedExam(aid, clsid,groupid).subscribe(
      (data : any) =>{        
        this.websiteService.examList= data;
      });
  }
  groupChange(event){
    const value = event.target.value;
    this.selectedGroupId = value;
    this.loadExam();
  }
  examChange(event){
    const value = event.target.value;
    this.selectedExamId = value;
    let selectElementText = event.target['options'][event.target['options'].selectedIndex].text;
    let classname = event.target['options'][event.target['options'].selectedIndex].className; 
    this.selectedExamType = classname.toLowerCase();
    //alert(this.selectedExamType);
    this.selectedExam = selectElementText;
  }
  getResult(){
    //alert(this.stdcode);
    this.websiteService.IsFailedAnySubject = false;
    document.getElementById('resultDv').style.display = 'none'; 
    document.getElementById('summativeResult').style.display = 'none';
    this.showloader();
    var examid=0;
    var stdCode="";
    if(this.selectedExamId != undefined){
      examid = this.selectedExamId;
    }
    if(this.stdcode != undefined){
      stdCode = this.stdcode;
    }
    if(this.selectedExamType.toLowerCase() == 'term'){
      this.websiteService.getStudentResultSummary(examid, stdCode).subscribe(
        (data : any) =>{     
             
          this.websiteService.studentResultSummary= data;
          this.hideloader();
        });
        document.getElementById('resultDv').style.display = 'block'; 
    }
    else if(this.selectedExamType == 'final'){

    }
    else{
      //alert(this.selectedExamType);
         
      this.websiteService.IsFailedAnySubject = false;
      this.websiteService.getStudentSummativeResult(examid, stdCode).subscribe(
        (data : any) =>{     
             
          this.websiteService.studentSummativeResult= data;
          this.websiteService.IsFailedAnySubject = false;
          this.hideloader();
        });
      document.getElementById('summativeResult').style.display = 'block';
      this.hideloader();
    }
  }
  filteredResult(examId: any, courseId: any){
    
    let x = this.websiteService.studentSummativeResult.StudentResult.filter(x=>x.ExamID === examId && x.CourseID === courseId);
    let test = x[0].TotalMark;
    return x;
    //return this.websiteService.studentSummativeResult.StudentResult.filter(x=>x.ExamID === examId && x.CourseID === courseId);
}
getTotalObtainedMark(courseId: any){
  let x = this.websiteService.studentSummativeResult.StudentResult.filter(x=> x.CourseID === courseId);
  let totalObtainedMark =0;
  for(var i=0; i< x.length;i++){
    totalObtainedMark+= x[i].TotalMark;
  }
  return totalObtainedMark;
}
getExamTotalMark(courseId: any){
  let x = this.websiteService.studentSummativeResult.StudentResult.filter(x=> x.CourseID === courseId);
  let examTotalMark =0;
  for(var i=0; i< x.length;i++){
    examTotalMark+= x[i].ExamTotalMark;
  }
  return examTotalMark;
}
getTotalMarkObtainedPercentage(courseId: any){
  let x = this.websiteService.studentSummativeResult.StudentResult.filter(x=> x.CourseID === courseId);
  let y = this.websiteService.studentSummativeResult.StudentResult.filter(x=> x.CourseID === courseId);
  let totalObtainedMark =0;
  for(var i=0; i< x.length;i++){
    totalObtainedMark+= x[i].TotalMark;
  }
  let examTotalMark =0;
  for(var i=0; i< y.length;i++){
    examTotalMark+= y[i].ExamTotalMark;
  }
  let totalMarksObtainedPercentage =0;
  if(examTotalMark != 0){
    totalMarksObtainedPercentage = totalObtainedMark*100/examTotalMark;
  }
  return totalMarksObtainedPercentage;
}
getStudentGrade(courseId: any){
  let resultGrade = this.websiteService.studentSummativeResult.ResultGrade;
  let x = this.websiteService.studentSummativeResult.StudentResult.filter(x=> x.CourseID === courseId);
  let y = this.websiteService.studentSummativeResult.StudentResult.filter(x=> x.CourseID === courseId);
  let totalObtainedMark =0;
  for(var i=0; i< x.length;i++){
    totalObtainedMark+= x[i].TotalMark;
  }
  let examTotalMark =0;
  for(var i=0; i< y.length;i++){
    examTotalMark+= y[i].ExamTotalMark;
  }
  let totalMarksObtainedPercentage =0;
  if(examTotalMark != 0){
    totalMarksObtainedPercentage = totalObtainedMark*100/examTotalMark;
  }
  let studentGrade = resultGrade.filter(x=> totalMarksObtainedPercentage >= x.LowerPercentage && totalMarksObtainedPercentage<=x.UpperPercentage)[0].Grade;
  if(this.websiteService.IsFailedAnySubject == false){
    if(studentGrade == 'F'){
      this.websiteService.IsFailedAnySubject = true;
    }
  }
  return studentGrade;
}
getStudentGPA(courseId: any){
  let resultGrade = this.websiteService.studentSummativeResult.ResultGrade;
  let x = this.websiteService.studentSummativeResult.StudentResult.filter(x=> x.CourseID === courseId);
  let y = this.websiteService.studentSummativeResult.StudentResult.filter(x=> x.CourseID === courseId);
  let totalObtainedMark =0;
  for(var i=0; i< x.length;i++){
    totalObtainedMark+= x[i].TotalMark;
  }
  let examTotalMark =0;
  for(var i=0; i< y.length;i++){
    examTotalMark+= y[i].ExamTotalMark;
  }
  let totalMarksObtainedPercentage =0;
  if(examTotalMark != 0){
    totalMarksObtainedPercentage = totalObtainedMark*100/examTotalMark;
  }
  let studentGPA = resultGrade.filter(x=> totalMarksObtainedPercentage >= x.LowerPercentage && totalMarksObtainedPercentage<=x.UpperPercentage)[0].GPA;
  return studentGPA;
}
  generatePdf(data) {
      html2canvas(data, { allowTaint: true }).then(canvas => {
       let HTML_Width = canvas.width;
       let HTML_Height = canvas.height;
       let top_left_margin = 10;
       let PDF_Width = HTML_Width + (top_left_margin * 2);
       let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
       let canvas_image_width = HTML_Width;
       let canvas_image_height = HTML_Height;
       let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
       canvas.getContext('2d');
       let imgData = canvas.toDataURL("image/jpeg", 1.0);
       let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
       pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
       for (let i = 1; i <= totalPDFPages; i++) {
         pdf.addPage([PDF_Width, PDF_Height], 'p');
         pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
       }
        pdf.save(this.websiteService.studentResultSummary?.ResultSummary?.StudentUniqueCode+"-"+ this.selectedExam +"-" + this.selectedAcademicYear+"-Result.pdf");
     });
   }
   public printThePage() {
    if(this.selectedExamType.toLowerCase() == 'term'){
      var printContent = document.getElementById("resultDv");
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContent.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
    }
    else{
      var printContent = document.getElementById("summativeResult");
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContent.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
    }
  }
}
