<!-- <p>test-photogallery works!</p>
<div class="basic-container">
	<h2>Basic Example</h2>
	<gallery id="basic-test" fluid [items]="items"></gallery>
</div>
<div class="lightbox-example">
	<h2>Lightbox Example</h2>
	<button mat-raised-button color="primary" (click)="lightbox.open(0)">Open Lightbox</button>


	<button mat-raised-button color="accent" (click)="lightbox.open(0, 'lightbox', {panelClass: 'fullscreen'})">Open Lightbox in fullscreen mode</button>
</div> -->
<div class="basic-container" style="display: none;">
	<h2>Basic Example</h2>
	<gallery id="basic-test" fluid [items]="items"></gallery>
</div>
<div class="gallerize-example">
	<h2>Gallerize Example</h2>
	<p class="container" gallerize>
		<img width="250" height="250" style="margin-right: 5px; margin-bottom: 5px;" *ngFor="let img of imageData" [src]="img.srcUrl.toString().replace('Uploads','http://democampus.demoschool1.astutebd.com/Uploads')">
	</p>
</div>
<input type="file" id="btnUpload" value="Upload" (change)="fileChange($event)" class="upload" />