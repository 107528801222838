
<app-navbar></app-navbar>
<!-- Banner Block -->
<!-- <div style="border: solid 1px red;width: 500px;height: 500px;position:absolute;z-index: 999;margin: auto;left:25%">Test</div> -->
<!-- <button id="myBtn">Open Modal</button> -->
<!-- The Modal -->
<div id="myModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content">
      <button class="close" id="close">&times;</button>
      <p>Some text in the Modal..</p>
      
    </div>
  
  </div>
  <div id="popupNotice" class="modal">

    <!-- Modal content -->
    <div class="modal-content">
      <div class="modal-header" [ngStyle]="{'background-color': this.websiteService.themeColor}">
        <span class="close" id="popupNoticeClose">&times;</span>
      </div>
      <div class="modal-body">
        <ng-container *ngFor="let item of websiteService.popupNoticeList;let i = index">
          
            <h4 style="border-top:solid 1px gray;text-align:center;padding:5px;color: #ffffff;" [ngStyle]="{'background': this.websiteService.navigationColor}">{{item.Title}}</h4>
            <img *ngIf="item.AttachmentUrl?.indexOf('.jpg') > 0" class="img-fluid" style="width:100%;height:100%" src="{{item.AttachmentUrl.replace('~/Uploads',websiteService.apiimagePath)}}" alt="Ban Slide">
            <div *ngIf="item.AttachmentUrl?.indexOf('.pdf') > 0" style="cursor: pointer;"><a style="cursor: pointer;" target="_blank" href="{{item.AttachmentUrl.replace('~/Uploads',websiteService.apiimagePath)}}"><img style="width:74px;height:70px;" src="assets/assets/img/pdf_big.png"></a><br>{{item.Title}}</div>
          </ng-container>
      </div>
      <div class="modal-footer" [ngStyle]="{'background-color': this.websiteService.themeColor}">
        This popup will close in: {{this.websiteService.noticeTimeLeft}} Second(s)
      </div>
    </div>
  
  </div>
<div class="banner-slider">
    <div class="container">
        <div class="news-block d-flex flex-wrap flex-md-nowrap align-items-center">
            <div class="info d-flex align-items-center">
                <div class="title">
                    News
                </div>
                <div class="message">
                    <marquee style="display: flex;height: 25px;">
                        <ul class="msg-items d-flex align-items-center">
                            <ng-container *ngFor="let item of websiteService.scrollingNews;let i = index">
                                <li>
                                    {{item.Title}}
                                </li>
                            </ng-container>
                            <li>
                        </ul>
                    </marquee>
                </div>
            </div>
            <div class="calender d-flex align-items-center">
                <span><a href="/#/calendar" style="cursor: pointer;color: white; text-decoration: none;">Calender</a></span>
                <span>{{this.websiteService.timeNow | date :'hh:mm a'}}</span>
                <span>{{this.websiteService.timeNow | date :'dd/MM/yy'}}</span>
            </div>
        </div>
        <!-- Slider -->
        <!-- Slider -->
        <div class="slider-block">                         
             <div id="carouselBullet" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <ng-container *ngFor="let item of websiteService.homeBanner;let i = index">
                        <li id="liImage{{i}}" data-target="#carouselBullet" data-slide-to="0" (click)="showSlideImage(i)" [class.active]="i==0"></li>
                    </ng-container>
                  </ol>
                <div class="carousel-inner">
                    <ng-container *ngFor="let item of websiteService.homeBanner;let i = index">
                        <div class="carousel-item" id="siImage{{i}}">
                            <img class="img-fluid" style="width:100%;height:100%" src="{{item.ImageUrl.replace('Uploads',websiteService.apiimagePath)}}" alt="Ban Slide">
        
                            <div class="carousel-caption">
                                <p [innerHTML]="item.Title"></p>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div> 
        </div>
    </div>
</div>


<!-- Wellcome Notice Block -->
<div class="welcome-notice">
    <div class="container">
        <div class="title">
            <h2>Welcome to {{websiteService.schoolInfo?.SchoolName}}</h2>
        </div>          
       <div class="row justify-content-center">
        <ng-container *ngFor="let item of websiteService.schoolAttribute;let i = index">
            <ng-container *ngIf="item.AttributeKey=='About School'">
                <div class="col-lg-4 col-md-6 py-2">
                    <div class="item">
                        <img class="img-fluid" src="{{item.ImageUrl.replace('Uploads',websiteService.apiimagePath)}}" alt="College">
                    </div>
                </div>
    
                <div class="col-lg-4 col-md-6 py-2">
                    <div class="item">
                        <div class="wn-text text-center text-md-left">
                            <p [innerHtml]="this.websiteService.unescapeHtmlString(item.ShortDescription)  | safe: 'html'"></p>    
                            <button (click)="openMorePage(item.Id)" class="btn btn-danger">Learn More..</button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

            <div class="col-lg-4 col-md-6 py-2">
                <div class="item">
                    <div class="notice-board">
                        <div style="height: 350px;overflow: hidden;">
                        <div class="title text-center">
                            Notice Board
                        </div>
                        <marquee onmouseover="this.stop();" onmouseout="this.start();" behavior="scroll" scrollamount="3" direction="up" style="height: 100%;background-color: red;" >
                            <ul class="notice-items">
                                <ng-container *ngFor="let item of websiteService.noticeBoard;let i = index">
                                    <li style="cursor: pointer;">
                                        <p style="cursor: pointer;">
                                            <img *ngIf="this.calculateDiff(item.NoticePublishDate) <=48" src="./assets/assets/img/newNotice.png" alt="New Notice">
                                            <a style="cursor: pointer;" (click)="openPage(item.NoticeID)">{{item.NoticeTitile}}</a> 
                                        </p>
                                        <div class="date">
                                            <i class="far fa-calendar-alt"></i> {{item.NoticePublishDate | date :'dd-MM-yy'}}
                                        </div>
                                    </li>
                                </ng-container>
                            </ul>
                          </marquee>
                        </div>
                    <div class="load-more text-right">
                        <a href="/#/morenotice">Load More..</a>
                    </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Board Members Message Block-->
<div class="members-messages">
    <div class="container">
        <div class="title">
            <h2>Message</h2>
        </div>
        <div class="row">
            <ng-container *ngFor="let item of websiteService.homeMessages;let i = index">
                <div [class.offset-lg-3]="websiteService.homeMessages.length==3 && i==0" [class.col-lg-12]="websiteService.homeMessages.length==1" [class.col-lg-6]="websiteService.homeMessages.length >1" class="py-2 py-md-4">
                    <div class="member d-flex">
                        <div class="thumb rounded overflow-hidden">
                            <img class="img-fluid" src="{{item.ImageUrl.toString().replace('Uploads',websiteService.apiimagePath)}}" alt="Chairman">
                        </div>
                        <div class="info">
                            <div class="designation">
                                {{item.Title.toString()}}
                                <span></span>
                            </div>
                            <div class="name">
                                {{item.AboutTitle.toString()}}
                            </div>
    
                            <p style="max-height: 210px;overflow: hidden;" [innerHtml]="this.websiteService.unescapeHtmlString(item.Message)  | safe: 'html'">
                                
                            </p>
    
                            <a style="cursor: pointer;" (click)="openMessagePage(item.ID)">Read More..</a>
                        </div>
                    </div>
                </div>
            </ng-container>           

        </div>
    </div>
</div>

<!-- Best Award Block -->
<div class="best-awards" *ngIf="this.websiteService.schoolAttribute != null">
    <div class="container">
        <div class="title">
            <h2>Why are we ideal?</h2>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6 pr-lg-2 pr-3 py-2 order-sm-9 order-lg-5">
                <ng-container *ngFor="let item of websiteService.schoolAttribute;let i = index">
                    <ng-container *ngIf="item.AttributeKey=='Our Experience'">
                        <div class="item exprience">
                            <h5>{{item.Title}}</h5>
                            <p [innerHtml]="this.websiteService.unescapeHtmlString(item.ShortDescription)  | safe: 'html'"></p>
                            <a style="cursor: pointer;" (click)="openMorePage(item.Id)">Learn More..</a>
                        </div>
                    </ng-container>
                </ng-container>                
            </div>

            <div class="col-lg-6 px-lg-2 px-3 py-2 order-sm-5 order-lg-9">
                <div class="item">
                    <div class="row">
                        <ng-container *ngFor="let item of websiteService.schoolAttribute;let i = index">
                            <ng-container *ngIf="item.AttributeKey=='Feature'">
                                <div class="col-md-6 px-sm-2 px-3 mb-2">
                                    <div class="service-item">
                                        <h5>{{item.Title}}</h5>
                                        <p [innerHtml]="this.websiteService.unescapeHtmlString(item.ShortDescription)  | safe: 'html'"></p>
                                        <a style="cursor: pointer;" (click)="openMorePage(item.Id)">Learn More..</a>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 pl-lg-2 pl-3 py-2 order-sm-11">
                <ng-container *ngFor="let item of websiteService.schoolAttribute;let i = index">
                    <ng-container *ngIf="item.AttributeKey=='Awards'">
                        <div class="item">
                            <div class="award-title text-center">
                                {{item.Title}}
                            </div>
                            <div class="award-image">
                                <img src="{{item.ImageUrl.replace('Uploads',websiteService.apiimagePath)}}" alt="Award" class="img-fluid">
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                
            </div>
        </div>
    </div>
</div>

<!-- Events Block -->
<div class="events-block">
    <div class="events-nav">
        <div class="container">
            <div class="title">
                <h2 class="text-white">For You</h2>
                <span></span>
            </div>
            <nav>
                <ul class="nav nav-tabs" id="nav-tab" role="tablist">
                    <li>
                        <a class="nav-link active" data-toggle="tab" href="#FeaturedNews" role="tab">Featured News</a>
                    </li>

                    <li>
                        <a class="nav-link" data-toggle="tab" href="#UpcomingEvents" role="tab">Upcoming Events</a>
                    </li>

                    <li>
                        <a class="nav-link" data-toggle="tab" href="#AllEvents" role="tab">All Events</a>
                    </li>

                    <li>
                        <a class="nav-link" data-toggle="tab" href="#Achievement" role="tab">Achievement</a>
                    </li>
                </ul>
              </nav>
        </div>
    </div>

    <div class="container">
        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="FeaturedNews" role="tabpanel">
                <div class="row justify-content-center">
                    <ng-container *ngFor="let item of websiteService.schoolAttribute;let i = index">
                        <ng-container *ngIf="item.AttributeKey=='Featured News'">
                            <div class="col-lg-4 col-sm-6">
                                <div class="card">
                                    <img class="img-fluid" src="{{item.ImageUrl.replace('Uploads',websiteService.apiimagePath)}}" class="card-img-top" alt="Event">
                                    <div class="card-body">
                                      <h5 class="card-title">{{item.Title}}</h5>
        
                                      <div class="date">
                                        <i class="far fa-calendar-alt"></i> {{item.CreatedOn}}
                                      </div>
        
                                      <p class="card-text" [innerHtml]="this.websiteService.unescapeHtmlString(item.ShortDescription)  | safe: 'html'"></p>
                                      <a style="cursor: pointer;" (click)="openMorePage(item.Id)">Read More..</a>
                                    </div>
                                  </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <div class="tab-pane fade" id="UpcomingEvents" role="tabpanel">
                <div class="row justify-content-center">
                    <ng-container *ngFor="let item of websiteService.schoolAttribute;let i = index">
                        <ng-container *ngIf="item.AttributeKey=='Upcoming Events'">
                            <div class="col-lg-4 col-sm-6">
                                <div class="card">
                                    <img class="img-fluid" src="{{item.ImageUrl.replace('Uploads',websiteService.apiimagePath)}}" class="card-img-top" alt="Event">
                                    <div class="card-body">
                                      <h5 class="card-title">{{item.Title}}</h5>
        
                                      <div class="date">
                                        <i class="far fa-calendar-alt"></i> {{item.CreatedOn}}
                                      </div>
        
                                      <p class="card-text" [innerHtml]="this.websiteService.unescapeHtmlString(item.ShortDescription)  | safe: 'html'"></p>
                                      <a style="cursor: pointer;" (click)="openMorePage(item.Id)">Read More..</a>
                                    </div>
                                  </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <div class="tab-pane fade" id="AllEvents" role="tabpanel">
                <div class="row justify-content-center">
                    <ng-container *ngFor="let item of websiteService.schoolAttribute;let i = index">
                        <ng-container *ngIf="item.AttributeKey=='All Events'">
                            <div class="col-lg-4 col-sm-6">
                                <div class="card">
                                    <img class="img-fluid" src="{{item.ImageUrl.replace('Uploads',websiteService.apiimagePath)}}" class="card-img-top" alt="Event">
                                    <div class="card-body">
                                      <h5 class="card-title">{{item.Title}}</h5>
        
                                      <div class="date">
                                        <i class="far fa-calendar-alt"></i> {{item.CreatedOn}}
                                      </div>
        
                                      <p class="card-text" [innerHtml]="this.websiteService.unescapeHtmlString(item.ShortDescription)  | safe: 'html'"></p>
                                      <a style="cursor: pointer;" (click)="openMorePage(item.Id)">Read More..</a>
                                    </div>
                                  </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <div class="tab-pane fade" id="Achievement" role="tabpanel">
                <div class="row justify-content-center">
                    <ng-container *ngFor="let item of websiteService.schoolAttribute;let i = index">
                        <ng-container *ngIf="item.AttributeKey=='Achievement'">
                            <div class="col-lg-4 col-sm-6">
                                <div class="card">
                                    <img class="img-fluid" src="{{item.ImageUrl.replace('Uploads',websiteService.apiimagePath)}}" class="card-img-top" alt="Event">
                                    <div class="card-body">
                                      <h5 class="card-title">{{item.Title}}</h5>
        
                                      <div class="date">
                                        <i class="far fa-calendar-alt"></i> {{item.CreatedOn}}
                                      </div>
        
                                      <p class="card-text" [innerHtml]="this.websiteService.unescapeHtmlString(item.ShortDescription)  | safe: 'html'"></p>
                                      <a style="cursor: pointer;" (click)="openMorePage(item.Id)">Read More..</a>
                                    </div>
                                  </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Institute Media Block -->
<div class="ins-media">
    <div class="media-tab">
        <div class="media-tab-nav">
            <div class="title">
               <div class="container">
                   <div class="row">
                       <div class="col-sm-8 offset-sm-2">
                        <h2>Institute Media</h2>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
                       </div>
                   </div>
               </div>
            </div>
            <div class="media-masonary">
                <div class="container">
                    <div class="nav-controls d-flex flex-wrap align-items-center justify-content-center">
                        <button type="button" data-filter="all" (click)="onMediaChange('all')">All</button>
                        <button type="button" data-filter=".images" (click)="onMediaChange('images')">Images</button>
                        <button type="button" data-filter=".audio" (click)="onMediaChange('audio')">Audio</button>
                        <button type="button" data-filter=".video" (click)="onMediaChange('video')">Video</button>
                    </div>
                    <div class="media-tab-items">
                        <div class="row no-gutters">
                                <ng-container *ngFor="let item of websiteService.gallaryImages;let i = index">
                                    <div  style="padding:1px" *ngIf="this.isImage || this.isAll" class="col-6 col-sm-4 col-md-3 mix images audio">
                                        <div class="item">
                                            <img class="img-fluid gallery" (click)="openModal();currentSlide(i+1)" src="{{item.ImageUrl.toString().replace('Uploads',websiteService.apiimagePath)}}" alt="{{item.Title}}">
                                        </div>
                                    </div>
                                </ng-container>
                            <div *ngIf="this.isAudio">
                                <div class="col-6 col-sm-4 col-md-3 mix images video">
                                    <div class="item">
                                        <!-- <img style="height:250px;" src="../../assets/assets/img/award.png" alt="" class="img-fluid"> -->
                                    </div>
                                </div>
                            </div>
                                <ng-container *ngFor="let item of websiteService.videoList;let i = index">
                                    <div *ngIf="this.isVideo" class="col-6 col-sm-4 col-md-3 mix images video" >
                                        <div class="item" style="padding-right: 5px;">
                                            <iframe [src]="item.YouTubeEmbedUrl | safe: 'url'" width="270" height="250" frameborder="0" webkitallowfullscreen mozallowfullscreen
      allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </ng-container>
                        </div>
                        <div class="load-more text-right mt-4">
                            <a class="btn btn-danger" href="/#/photogallery">Load More..</a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="myModalGallery" class="modal2">
                
                <div class="modal-content">
                    <div class="modal-header" [ngStyle]="{'background-color': this.websiteService.themeColor}">
                        <label>Gallery</label>
                        <span class="close cursor" (click)="closeModal()">&times;</span>
                    </div>
                    <div class="modal-body">
                    <ng-container *ngFor="let item of websiteService.gallaryImages;let i = index">
                        <div class="mySlides">
                            <div class="numbertext">{{i+1}} / {{websiteService.gallaryImages.length}}</div>
                            <img class="demo" src="{{item.ImageUrl.toString().replace('Uploads',websiteService.apiimagePath)}}"  alt="{{item.Title}}" style="width:100%">
                          </div>
                    </ng-container>
                  
                  <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
                  <a class="next" (click)="plusSlides(1)">&#10095;</a>
              
                  <div class="caption-container">
                    <p id="caption"></p>
                  </div>
                  <!-- <ng-container *ngFor="let item of websiteService.gallaryImages;let i = index">
                    <div class="column">
                        <img class="demo cursor" src="{{item.ImageUrl.toString().replace('Uploads',websiteService.apiimagePath)}}" style="width:100%" (click)="currentSlide(i+1)"  alt="{{item.Title}}">
                      </div>
                    </ng-container> -->
                </div>
                </div>
              </div>
        </div>
        <div class="media-footer-bg">
            <img class="img-fluid" src="assets/assets/img/in-mediaBg2.png" alt="Media">
        </div>
    </div>
</div>
<div class="best-awards">
    <div class="container">
        <div class="title">
            <h2>Visitor</h2>
        </div>
        <div class="row">
            <ng-container *ngFor="let item of websiteService.visitorTrackingSummary;let i = index">

            
            <div class="col-md-3" style="text-align:center;">
                <h3 style="background-color: #ea2027;padding: 5px;color:white;margin-bottom: .05rem;">Today</h3>
                <div style="border: solid 1px #e3e3e3;font-weight: bold;background-color: sandybrown; color: #ffffff; font-size: 24px;padding:5px;">{{item.TodayVisited}}</div>
            </div>
            <div class="col-md-3" style="text-align:center;">
                <h3 style="background-color: #ea2027;padding: 5px;color:white;margin-bottom: .05rem;">Last Week</h3>
                <div style="border: solid 1px #e3e3e3;font-weight: bold;background-color: sandybrown; color: #ffffff; font-size: 24px;padding:5px;">{{item.LastWeekVisited}}</div>
            </div>
            <div class="col-md-3" style="text-align:center;">
                
                <h3 style="background-color: #ea2027;padding: 5px;color:white;margin-bottom: .05rem;">Last Month</h3>
                <div style="border: solid 1px #e3e3e3;font-weight: bold;background-color: sandybrown; color: #ffffff; font-size: 24px;padding:5px;">{{item.LastMonthVisited}}</div>
            </div>
            <div class="col-md-3" style="text-align:center;">
                
                <h3 style="background-color: #ea2027;padding: 5px;color:white;margin-bottom: .05rem;">Total</h3>
                <div style="border: solid 1px #e3e3e3;font-weight: bold;background-color: sandybrown; color: #ffffff; font-size: 24px;padding:5px;">{{item.TotalVisited}}</div>
            </div>
        </ng-container>
        </div>
    </div>
</div>
<app-footer></app-footer>




