<app-navbar></app-navbar>
<div style="border:solid 6px whitesmoke;background-color: gray;">
<div class="container site-content">
    <div class="row">
        <div class="col col-md-12">
            <div class="card box-shadow">
                <div class="card-header header-shadow">
                    <b>{{this.websiteService.moreInfo.Title}}</b>
                </div>
                <div class="card-body">
                    <img #moreinfoimg class="moreinfoimg" src="{{this.websiteService.moreInfo.ImageUrl.replace('Uploads',this.websiteService.apiimagePath)}}" alt="{{this.websiteService.moreInfo.Title}}" (error)="moreinfoimg.hidden = true">
                    <div class="moreinfodetails" [innerHtml]="this.websiteService.unescapeHtmlString(this.websiteService.moreInfo.LongDescription)  | safe: 'html'"></div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<app-footer></app-footer>
