import { Component, OnInit } from '@angular/core';
import { WebsiteService } from '../shared/website.service';
import { Router } from '@angular/router';
import { HttpClient  } from '@angular/common/http';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  googleLocationUrl;
  currentYear;
  constructor(private router: Router, public websiteService: WebsiteService,public http:HttpClient) {
    this.loadFooterMenu();
    this.loadGoogleMapLocation();
    this.currentYear=new Date().getFullYear();
   }

  ngOnInit() {
    this.websiteService.themeColor=this.getThemeColor(localStorage.getItem('ThemeColor'));
  }
  getThemeColor(selectedTheme){
    var themeColor='#af0006';
    switch(selectedTheme) { 
      case 'black': { 
         themeColor='#232323';
         break; 
      } 
      case 'blue': { 
         themeColor='#006CB0';
         break; 
      } 
      case 'green': { 
        themeColor='#9eb729';
        break; 
     } 
     case 'red': { 
      themeColor='#b31818';
      break; 
      } 
      case 'theme1': { 
        themeColor='#264F3B';
        break; 
      } 
      
      case 'theme2': { 
        themeColor='#3B5998';
        break; 
      } 
      case 'theme3': { 
        themeColor='#23AF0A';
        break; 
      } 
      case 'theme4': { 
        themeColor='#A74203';
        break; 
      } 
      case 'theme5': { 
        themeColor='#0E0E44';
        break; 
      } 
      case 'theme6': { 
        themeColor='#330033';
        break; 
      } 
      case 'theme7': { 
        themeColor='#006A4E';
        break; 
      } 
      case 'theme8': { 
        themeColor='#5b0b26';
        break; 
      } 
      case 'theme9': { 
        themeColor='#087B39';
        break; 
      } 
      case 'theme10': { 
        themeColor='#5f424c';
        break; 
      } 
      case 'theme11': { 
        themeColor='#008B8B';
        break; 
      } 
      case 'voilet': { 
        themeColor='#443266';
        break; 
      } 
      default: { 
         //statements; 
         break; 
      } 
   } 
   return themeColor;
  }
  loadFooterMenu(){
    this.websiteService.getGetFooterMenu().subscribe(
      (data : any) =>{        
        this.websiteService.footerMenu= data;
      });
  }
  loadGoogleMapLocation(){
    this.websiteService.getGoogleMapLocation().subscribe(
      (data : any) =>{     
        this.googleLocationUrl=data;
      });
  }
  openPage(id,title,menuurl){
    
    //this.router.navigate(['/details'], { queryParams: { pid: id} });
    if(title == 'Home'){
      this.router.navigate(['/home']);
    }
    else{
      if(menuurl == ''){
        this.redirectTo('/details',id);
      }
      else{
        window.open(menuurl, "_blank");
      }
    }
  }
  redirectTo(uri,id) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([uri, {refresh: (new Date).getTime()}], { queryParams: { pid: id} }));
    
  }
}
