<app-navbar></app-navbar>
<!-- Students Information Block -->
<div class="students-info">
    <div class="container">
        <div class="info">
            <div class="title d-flex flex-wrap align-items-center justify-content-center justify-sm-content-start mb-sm-4">
                <h2 class="mb-0 mr-3 my-3">Students Information</h2>
                <div class="teacher-search search-box ml-md-auto my-3">
                    <div class="d-flex align-items-center">
                        <input type="text" [(ngModel)]="term" placeholder="Search">
                        <button class="btn">Search</button>
                    </div>
                </div>
            </div>
            <!-- <p class="px-0 px-md-5 text-center mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p> -->
        </div>

        <div class="students-info-lists rounded text-center mt-4 mt-sm-5">
            <div class="d-flex justify-content-center">
                <div id="loadingstatus">
                    <div class="spinner-border" role="status">
                        <span class="sr-only" id="loading"></span>
                    </div>&nbsp;Loading..
                </div>
            </div>
            <div class="load-more text-right mt-2 mt-sm-3" style="padding-bottom: 5px;">
                <span style="font-weight: bold;">Total Student: {{this.totalStudent}}&nbsp;&nbsp;</span>
                <button class="btn btn-danger" (click)="loadAllStudent($event)">Load All..</button>
            </div>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Sl.</th>
                            <th>Student Photo</th>
                            <th>Std. Code</th>
                            <th>Student Name</th>
                            <th>Class, Group</th>
                            <th>Shift, Version</th>
                            <th>Section</th>
                            <th>Religion</th>
                            <th>Gender</th>
                            <!-- <th>Address, Contact Number</th> -->
                        </tr>
                    </thead>

                    <tbody>
                        <ng-container *ngFor="let item of websiteService.studentList | filter:term;let i = index">
                            <tr>
                                <td>{{i+1}}</td>
                                <td>
                                    <div class="std-thumb">
                                        <img class="img-fluid" src="{{item.PasportSizePhotoUrl?.replace('~/Uploads',websiteService.apiimagePath)}}" alt="Students">
                                    </div>
                                </td>
                                <td>{{item.StudentUniqueCode}}</td>
    
                                <td>{{item.FullName}}</td>
    
                                <td>
                                    <span>{{item?.ClassName}}</span>
                                    <span>{{item?.GroupName}}</span>
                                </td>
    
                                <td>
                                    <span>{{item?.ShiftName}}</span>
                                    <span>{{item?.VersionName}}</span>
                                </td>
                                    
                                <td>
                                    {{item?.SectionName}}
                                </td>
    
                                <td>{{item?.Religion}}</td>
    
                                <td>{{item?.Gender}}</td>
                                
                                <!-- <td>
                                    9/15, Tajmoholroad, Mohammadpur, Dhaka.
                                </td> -->
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-center">
                <div id="loadingstatus2">
                    <div class="spinner-border" role="status">
                        <span class="sr-only" id="loading"></span>
                    </div>&nbsp;Loading..
                </div>
            </div>
            <div class="load-more text-right mt-2 mt-sm-3">
                <button class="btn btn-danger" (click)="loadAllStudent($event)">Load All..</button>
                <button class="btn btn-danger" (click)="loadMore($event)">Load More..</button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
