import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {
  rootUrl = 'http://localhost:47504';
  timeNow;
  timeNowTimer;
  siteTopMenu;
  scrollingNews;
  homeBanner;
  noticeBoard;
  homeMessages;
  schoolInfo;
  gallaryImages;
  schoolAttribute;
  footerMenu;
  pageId;
  pageContent;
  pageAttachment;
  allNotice;
  notice;
  noticeId;
  moreInfo;
  attributeId;
  socialLinks;
  studentList;
  teacherList;
  teacherDetails;
  managingcommitteeList;
  managingCommitteeTypeCode;
  managingCommitteeTypeName;
  employeeListAll;
  startPage=0;
  pageSize=100;
  startPageTeacher=0;
  pageSizeTeacher=100;
  homeMessageDetails;
  homeMessageId;
  systemUrl;
  themeColor;
  navigationColor;
  circularInfo;
  popupNoticeList;
  popupNoticeTimer;
  noticeTimeLeft=120;
  academicYear;
  classList;
  groupList;
  classDetails;
  examList;
  studentResultSummary;
  studentSummativeResult;
  IsFailedAnySubject:boolean = false;
  visitorTrackingSummary;
  apiimagePath='http://democampus.demoschool1.astutebd.com/';
  videoList;
  pageTitle;
  constructor(public http: HttpClient, private router : Router) { 
    if(window.location.href.indexOf('localhost')>0)
    {
      //this.rootUrl = 'http://localhost:47504/';
      this.rootUrl = 'https://api3.astutebd.com';
      this.apiimagePath = 'http://democampus.demoschool1.astutebd.com/Uploads';
    } 
    else{
        this.rootUrl = 'https://api3.astutebd.com';
        this.apiimagePath = 'https://apiimage.astutebd.com/Uploads';
      /*if(window.location.href.indexOf('ipscctg.edu.bd')>0)
      {
        this.rootUrl = 'https://api3.astutebd.com';
        this.apiimagePath = 'https://apiimage.astutebd.com/Uploads';
      }
      else{
        this.rootUrl = 'http://api2.astutebd.com';
        this.apiimagePath = 'http://democampus.demoschool1.astutebd.com/Uploads';
      }*/
    }
  }
  unescapeHtmlString(val){    
    //var htmtemp=this.transformYourHtml(val);
    return unescape(val.toString());
  }
  startTimeNowTimer(){
    //
    this.timeNowTimer= setInterval(() => {
      this.timeNow =new Date((new Date).getTime() + 1000);
      localStorage.setItem('timeNow', this.timeNow);
    },1000);
  }
  getSiteTopMenu(start, end) {
    //
    return this.http.get(this.rootUrl + '/api/Website/GetSiteTopMenuNew?start='+start+'&end='+end);
  }
  getScrollingNews(){
    return this.http.get(this.rootUrl + '/api/Website/GetScrollingNews');
  }
  getHomeBanner(){
    return this.http.get(this.rootUrl + '/api/Website/GetHomeBanner');
  }
  getNotice(){
    return this.http.get(this.rootUrl + '/api/Website/GetNotice');
  }
  getHomeMessages(){
    return this.http.get(this.rootUrl + '/api/Website/GetHomeMessages');
  }
  getShcoolInfo(){
    return this.http.get(this.rootUrl + '/api/Website/GetShcoolInfo');
  }
  getGallery(){
    return this.http.get(this.rootUrl + '/api/Website/GetGallery');
  }
  getVideos(){
    return this.http.get(this.rootUrl + '/api/Website/GetVideo');
  }
  getSchoolAttributeByKey(key){
    return this.http.get(this.rootUrl + '/api/Website/GetSchoolAttributeByKey?key='+key);
  }
  getGetFooterMenu(){
    return this.http.get(this.rootUrl + '/api/Website/GetFooterMenu');
  }
  getGoogleMapLocation(){
    return this.http.get(this.rootUrl + '/api/Website/GetGoogleMapLocation');
  }
  getPageContent(pid){
    return this.http.get(this.rootUrl + '/api/Website/GetPageContent?pid='+pid);
  }
  getPageAttachment(pid){
    return this.http.get(this.rootUrl + '/api/Website/GetPageAttachment?pageContentID='+pid);
  }
  getAllNotice(){
    return this.http.get(this.rootUrl + '/api/Website/GetAllNotice');
  }
  getNoticeById(nid){
    return this.http.get(this.rootUrl + '/api/Website/GetNoticeById?noticeID='+nid);
  }
  getNoticeByIdWithCheckDevice(nid, isMobile){
    
    return this.http.get(this.rootUrl + '/api/Website/GetNoticeByIdWithCheckDevice?noticeID='+nid+'&isMobile='+ isMobile);
  }
  getSchoolAttributeById(id){
    return this.http.get(this.rootUrl + '/api/Website/GetSchoolAttributeById?Id='+id);
  }
  getSocialLinks(){
    return this.http.get(this.rootUrl + '/api/Website/GetSocialLinks');
  }
  getAllStudent(start, pageSize){
    return this.http.get(this.rootUrl + '/api/Website/GetAllStudent?start='+start+'&pageSize='+pageSize);
  }
  getTeacherInformation(start, pageSize){
    return this.http.get(this.rootUrl + '/api/Website/GetTeacherInformation?start='+start+'&pageSize='+pageSize);
  }
  getTeacherDetails(employeeId){
    return this.http.get(this.rootUrl+'/api/Website/GetTeacherDetails?employeeID='+employeeId);
  }
  getEmployeeList(dept){
    return this.http.get(this.rootUrl + '/api/Website/GetEmployeeList?dept='+dept);
  }
  getManagingcommittee(committeeType){
    return this.http.get(this.rootUrl + '/api/Website/GetManagingCommittee?type='+committeeType);
  }
  getHomeMessageById(id){
    return this.http.get(this.rootUrl + '/api/Website/GetHomeMessageById?Id='+id);
  }
  getCircular(){
    return this.http.get(this.rootUrl + '/api/Website/GetCircular');
  }
  getPopupNotice(){
    return this.http.get(this.rootUrl+'/api/Website/GetPopupNotice');
  }
  getAcademicYear(){
    return this.http.get(this.rootUrl+'/api/Website/GetAcademicYear');
  }
  getClass(){
    return this.http.get(this.rootUrl+'/api/Website/GetAllClass');
  }
  getGroup(){
    return this.http.get(this.rootUrl+'/api/Website/GetAllGroup');
  }
  getClassById(classId){
    return this.http.get(this.rootUrl+'/api/Website/GetClassById?classId='+ classId);
  }
  getPublishedExam(academicYearId, classId, groupId){
    return this.http.get(this.rootUrl+'/api/Website/GetAllPublishedExam?academicYearId='+ academicYearId+'&classId='+ classId+'&groupId='+groupId);
  }
  getStudentResultSummary(examId,stdCode){
    return this.http.get(this.rootUrl+'/api/Website/GetStudentResultSummary?examId='+ examId+'&stdCode='+ stdCode);
  }
  getExamByExamId(examId){
    return this.http.get(this.rootUrl+'/api/Website/GetExamByExamId?examId='+ examId);
  }
  getStudentSummativeResult(examId,stdCode){
    return this.http.get(this.rootUrl+'/api/Website/GetStudentSummativeResult?examId='+ examId+'&stdCode='+ stdCode);
  }
  getAllAcademicEvent(){
    return this.http.get(this.rootUrl+'/api/Website/GetAllAcademicEvent');
  }
  getAllGallery(){
    return this.http.get(this.rootUrl+'/api/Website/GetAllGallery');
  }
  getVisitorTrackingSummary(){
    return this.http.get(this.rootUrl+'/api/Website/GetVisitorTrackingSummary');
  }
  uploadFile(formdata){
    return this.http.post(this.rootUrl+'/api/Teacher/UploadJsonFile', formdata);
  }
  checkBoardRoll(circularid, rollnumber) {
    return this.http.get(this.rootUrl+'/api/Website/CheckBoardRoll?circularid='+ circularid+'&rollnumber='+ rollnumber);
  }
}
