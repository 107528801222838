import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { WebsiteService } from '../shared/website.service';
import { Router } from '@angular/router';
import { HttpClient  } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DetailsComponent implements OnInit {

  constructor(private route: ActivatedRoute,private router: Router, public websiteService: WebsiteService,public http:HttpClient) { }

  ngOnInit() {
    this.websiteService.pageContent="";
    this.websiteService.pageAttachment = "";

    this.route.queryParams.subscribe(params => {      
      this.websiteService.pageId=params['pid'];
    });
    this.websiteService.getPageContent(this.websiteService.pageId).subscribe(
      (data : any) =>{       
         
        this.websiteService.pageContent= data;
        if(this.websiteService.pageContent.PageContent == null){
          this.websiteService.pageContent.PageContent="";
        }
        if(this.websiteService.pageContent.Title == null){
          this.websiteService.pageContent.Title="";
        }
      });
      this.websiteService.getPageAttachment(this.websiteService.pageId).subscribe(
        (data : any) =>{       
          // 
          this.websiteService.pageAttachment= data;
        });
  }

}
