<app-navbar></app-navbar>
<div style="border:solid 6px whitesmoke;background-color: gray;">
<div class="container site-content">
    <div class="row">
        <div class="col col-md-12">
            <div class="card box-shadow">
                <div class="card-header header-shadow">
                    <b>{{this.websiteService.notice.NoticeTitile}}</b>
                </div>
                <div class="card-body">
                   <a href="/#/morenotice">< Back to Notice Board</a>
                    <div [innerHtml]="this.websiteService.unescapeHtmlString(this.websiteService.notice?.NoticeDescription)  | safe: 'html'"></div>
                    <div [innerHtml]="this.websiteService.unescapeHtmlString(this.websiteService.notice?.NoticeHtml)  | safe: 'html'"></div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<app-footer></app-footer>
