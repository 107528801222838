<!-- Footer Block -->
<div class="footer-block">
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-3 col-sm-6 py-3">
                <div class="about-footer">
                    <div class="footer-title">
                        <img src="assets/assets/img/brand-logo.png" alt="Brand">
                    </div>
                    <div class="about">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsumgravida. Risus commodo viverra maecenas. </p>
                        <button class="btn btn-danger">Read More</button>
                    </div>
                </div>
            </div> -->

            <div class="col-lg-3 col-sm-6 py-3">
                <div class="location">
                    <div class="footer-title">
                        Location
                    </div>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur</p> -->
                    <div class="mapouter">
                        <div class="gmap_canvas">
                            <iframe [src]="this.googleLocationUrl | safe : 'resourceUrl'" width="250" height="250" id="gmap_canvas" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                        </div>
                    </div>
                    <!-- <img class="img-fluid mt-4" src="assets/assets/img/map.png" alt="Map"> -->
                </div>
            </div>
            <ng-container *ngFor="let item of websiteService.footerMenu;let i = index">
                <div class="col-lg-3 col-sm-6 py-3" *ngIf="i <2">
                    <div class="quick-link">
                        <div class="footer-title">
                            {{item.ParentMenu.Title}}
                        </div>
    
                        <ul>
                            <ng-container *ngFor="let item2 of item.ChildMenu;let i = index">
                                <li>
                                    <!-- <a [href]="item2.NavigateUrl | safe : 'resourceUrl'" target="_blank">{{item2.Title}}</a>  -->
                                    <a (click)="openPage(item2.ID,item2.Title,item2.NavigateUrl)"  style="cursor: pointer;">{{item2.Title}}</a> 
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </ng-container>
            <!-- <div class="col-lg-3 col-sm-6 py-3">
                <div class="quick-link">
                    <div class="footer-title">
                        Quick Links
                    </div>

                    <ul>
                        <ng-container *ngFor="let item of websiteService.footerMenu;let i = index">
                            <li>
                                <a href="#">{{item.ParentMenu.Title}}</a> 
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div> -->

            <div class="col-lg-3 col-sm-6 py-3">
                <div class="contact">
                    <div class="footer-title">
                        Contact Us
                    </div>

                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p> -->

                    <ul>
                        <ng-container *ngFor="let item of websiteService.schoolAttribute;let i = index">
                            <ng-container *ngIf="item.AttributeKey=='Contact us'">
                                <li><i class="fas fa-map-marker"></i><span  [innerHtml]="this.websiteService.unescapeHtmlString(item.Title)  | safe: 'html'"></span></li>
                                <li *ngIf="item.ShortDescription != ''"><a href="tel:{{item.ShortDescription}}"><i class="fas fa-phone-square"></i> <span  [innerHtml]="this.websiteService.unescapeHtmlString(item.ShortDescription)  | safe: 'html'"></span></a></li>
                                <li *ngIf="item.LongDescription != ''"><a href="mailto:{{item.LongDescription}}"><i class="fas fa-envelope-square"></i><span  [innerHtml]="this.websiteService.unescapeHtmlString(item.LongDescription)  | safe: 'html'"></span></a></li>
                            </ng-container>
                        </ng-container>
                        <!-- <li>
                            <i class="fas fa-map-marker"></i>
                            <ng-container *ngFor="let item of websiteService.schoolAttribute;let i = index">
                                <ng-container *ngIf="item.AttributeKey=='Contact us'">
                                    {{item.Title}}
                                </ng-container>
                            </ng-container>
                        </li>

                        <li>
                            <ng-container *ngFor="let item of websiteService.schoolAttribute;let i = index">
                                <ng-container *ngIf="item.AttributeKey=='Contact us'">
                                    <a href="tel:{{item.ShortDescription}}"><i class="fas fa-phone-square"></i> {{item.ShortDescription}}</a>
                                </ng-container>
                            </ng-container>
                        </li>

                        <li>
                            <ng-container *ngFor="let item of websiteService.schoolAttribute;let i = index">
                                <ng-container *ngIf="item.AttributeKey=='Contact us'">
                                    <a href="mailto:{{item.LongDescription}}"><i class="fas fa-envelope-square"></i> {{item.LongDescription}}</a>
                                    
                                </ng-container>
                            </ng-container>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Copyright Block -->
<div class="copyright-block" [ngStyle]="{'background-color': this.websiteService.themeColor}">
    <div class="container text-center" style="color: white;">
        {{this.currentYear}} @<a target="_blank" href="http://astutebd.com/">ASTUTE</a> - Powered by <a target="_blank" href="http://www.banglafire.com/">Banglafire Solution Ltd.</a> All rights reserved.
    </div>
</div>
